import { SET_VALUE_PW, setValuePW, podgrzewanieWody, CALC_TANK_VOL, calcTankVol } from '../actionTypes/podgrzewanieWodyTypes';
// import _ from 'lodash';

type ActionTypes = setValuePW | calcTankVol;

const initialState:podgrzewanieWody = {
    waterHeating: true,
    selectionMethod: undefined,
    habitantsNo: 1,
    waterUsage: 0,
    tapTemp: 40,
    tankVolumeCalculator: 200,
    tankWaterTemp: 45,
}

export function podgrzewanieWodyReducer(state = initialState, action: ActionTypes) {
    switch(action.type) {
        case SET_VALUE_PW: {
            const { element, value } = action.payload;
            return {
                ...state, 
                [element]: value
            }
        }
        case CALC_TANK_VOL: {
            let tank; 
            const { waterUsage, habitantsNo } = state;
            const requiredWater = waterUsage * habitantsNo;

            if(requiredWater < 180) tank = 170;
            else if(requiredWater < 230) tank = 200;
            else if(requiredWater < 330) tank = 300;
            else if(requiredWater < 430) tank = 400;
            else tank = 500;
            return {
                ...state, 
                'tankVolume': tank
            }
        }
        default: {
            return state
        }
    }
}
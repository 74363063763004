import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { setValuePW, calcTankVol, setFilter } from '../../../redux/actions';
import { podgrzewanieWody } from '../../../redux/actionTypes/podgrzewanieWodyTypes';
import KalkulatorObjetosci from './KalkulatorObjetosci';
import { NotificationManager } from 'react-notifications';
// import InfoZasobniki from './InfoZasobniki';
// import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Tooltip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined';
import clsx from 'clsx';
import Popup from 'reactjs-popup';
import InfoZasobniki from './InfoZasobniki';



interface OwnProps {
    setActiveStep: Function
}

interface DispatchProps {
    setValuePW: Function,
    calcTankVol: Function,
    setFilter: Function,
}

type Props = OwnProps & DispatchProps & podgrzewanieWody;

function PodgrzewanieWodyComponent(props: Props) {

    const { 
        setValuePW,
        waterHeating,

        tankVolume, calcTankVol,
        habitantsNo,
        waterUsage,
        buildinTank,
        setFilter, 
        setActiveStep
    } = props;

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => setActiveStep(4), []);

    useEffect(() => {
        if(habitantsNo * waterUsage > 500) {
            NotificationManager.warning('Maksymalna pojemność zbiornika  to 500 l.');
        }

        if(buildinTank === true && tankVolume && tankVolume > 300) {
            setValuePW( 'tankVolume', 300 );
            NotificationManager.warning('Jednostki wewnętrzne mają maksymalną pojemność zbiornika = 300 l.')
        }
        if(buildinTank === false && tankVolume && tankVolume < 300) {
            setValuePW( 'tankVolume', 300 );
            NotificationManager.warning('Jednostki zewnętrzne mają minimalną pojemność zbiornika = 300 l.')
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [habitantsNo, waterUsage, buildinTank]);


    useEffect(() => {
        setFilter('waterHeating', waterHeating);
        setFilter('tankCapacity', tankVolume);
        setFilter('buildInTank', buildinTank);
    }, [tankVolume, waterHeating, setFilter, buildinTank]);

    useEffect( () => {
        if(!waterHeating) setValuePW( 'buildinTank', null );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [waterHeating]);

    return (
        <Container style={{
            marginBottom: '9rem'
        }}>
            <Container className={ clsx( 'szacowanie-container', '' ) } style={{
                marginTop: '0',
                marginBottom: '1rem'
            }}>
                <Row>
                    <Col>
                        <h3 style={{marginBottom: '1rem'}}>Czy pompa ciepła ma podgrzewać ciepłą wodę użytkową (CWU)?</h3>
                        <ToggleButtonGroup
                            value={ waterHeating }
                            exclusive
                            onChange={ (e, val) => {
                                setValuePW( 'waterHeating', val );
                            }}
                            aria-label="Czy pompa ciepła ma podgrzewać cipłą wodę użytkową (CWU)?"
                            style={{marginBottom: '1rem',}}
                        >
                            <ToggleButton value={ true } aria-label="tak">
                                <Tooltip placement="top" title="">
                                    <div>Tak</div>
                                </Tooltip>
                            </ToggleButton>
                            <ToggleButton value={ false } aria-label="nie">
                                <Tooltip placement="top" title="">
                                    <div>Nie</div>
                                </Tooltip>
                            </ToggleButton>
                        </ToggleButtonGroup>

                    </Col>
                </Row>
            </Container>

            { waterHeating === true ? 

            <Container className={ clsx( 'szacowanie-container', '' ) } style={{
                marginTop: '0',
                marginBottom: '0'
            }}>
                <Row>
                    <Col xl={6}>
                        <p>Zasobnik ciepłej wody użytkowej zintegrowany z jednostką wewnętrzną?<br />
                            <small>Jeśli nie masz pewności - pozostaw puste.</small></p>
                        <ToggleButtonGroup
                            value={buildinTank}
                            exclusive
                            onChange={ (e, val) => {
                                setValuePW( 'buildinTank', val );

                            } }
                            aria-label="Zasobnik ciepłej wody użytkowej zintegrowany z jednostką wewnętrzną?"
                            style={{marginBottom: '1rem',}}
                        >
                            <ToggleButton value={true} aria-label="tak">
                                <Tooltip placement="top" title="">
                                    <div>Tak</div>
                                </Tooltip>
                            </ToggleButton>
                            <ToggleButton value={false} aria-label="nie">
                                <Tooltip placement="top" title="">
                                    <div>Nie</div>
                                </Tooltip>
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <Popup
                            trigger={
                                <ContactSupportOutlinedIcon fontSize="small" style={{marginLeft: '0.6rem'}} />
                            }
                            on={['click']}
                            modal
                            contentStyle={{ padding: '0.7rem 0', width: '80vw', height: '80vh', overflow: 'auto' }}
                            overlayStyle={{ zIndex: 9999 }}
                        >
                            <InfoZasobniki />
                        </Popup> 
                        <Accordion style={{ marginBottom: '2rem'}}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <ContactSupportOutlinedIcon fontSize="small" style={{marginRight: '0.6rem'}} />Dobierz pojemność zasobnika na podstawie informacji o&nbsp;mieszkańcach
                            </AccordionSummary>
                            <AccordionDetails>
                                <Row>
                                    <Col style={{ width: '100%' }}>
                                        <p>Podaj liczbę mieszkańców</p>
                                            <TextField 
                                                id="habitantsNo" 
                                                label="Liczba mieszkańców" 
                                                variant="outlined" 
                                                value={ habitantsNo }
                                                onChange={ (e) => {
                                                    setValuePW( 'habitantsNo', e.target.value );
                                                    calcTankVol();
                                                }}
                                                InputProps={{
                                                    inputProps: { min: 1 },
                                                    // endAdornment: <InputAdornment position="end">os</InputAdornment>
                                                }}
                                                type="number"
                                            />
                                    </Col>
                                    <Col style={{ width: '100%' }}>
                                        <p>Wybierz zużycie wody</p>
                                        <ToggleButtonGroup 
                                            orientation="vertical" 
                                            exclusive 
                                            value={ waterUsage } 
                                            onChange={ (e, val) => {
                                                setValuePW( 'waterUsage', val );
                                                calcTankVol();
                                            }}
                                            style={{ width: '100%' }}
                                        >
                                            <ToggleButton value={40} aria-label="Ekonomiczne">
                                                Ekonomiczne: <br/>40l/os/dobę
                                            </ToggleButton>
                                            <ToggleButton value={50} aria-label="Standardowe">
                                                Standardowe: <br/>50l/os/dobę
                                            </ToggleButton>
                                            <ToggleButton value={60} aria-label="Powiększone">
                                                Powiększone: <br/>60l/os/dobę
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Col>
                                </Row>
                            </AccordionDetails>
                        </Accordion>

                        <ToggleButtonGroup 
                            orientation="vertical" 
                            style={{ width: '100%'}}
                            exclusive 
                            value={ tankVolume } 
                            onChange={ (e, val) => {
                                setValuePW( 'tankVolume', val );
                            }}
                        >
                            <ToggleButton 
                                value={170}
                                aria-label="Zasobnik 170l" 
                                disabled={buildinTank !== false ? false : true}
                            >
                                Zasobnik 170l
                            </ToggleButton>
                            <ToggleButton 
                                value={200}
                                aria-label="Zasobnik 200l" 
                                disabled={buildinTank !== false ? false : true}
                            >
                                Zasobnik 200l
                            </ToggleButton>
                            <ToggleButton 
                                value={300}
                                aria-label="Zasobnik 300l">
                                Zasobnik 300l
                            </ToggleButton>
                            <ToggleButton 
                                value={400}
                                aria-label="Zasobnik 400l" 
                                disabled={buildinTank ? true : false}
                            >
                                Zasobnik 400l
                            </ToggleButton>
                            <ToggleButton 
                                value={500}
                                aria-label="Zasobnik 500l" 
                                disabled={buildinTank ? true : false}
                            >
                                Zasobnik 500l
                            </ToggleButton>

                        </ToggleButtonGroup>
                    </Col>
                    <Col xl={6}>
                        <KalkulatorObjetosci />
                    </Col>
                </Row>

            </Container> : ''}

        </Container>
    );
}


function mapStateToProps(store: any) {
    const { podgrzewanieWodyReducer } = store;
    return podgrzewanieWodyReducer;
}

export default connect<podgrzewanieWody, DispatchProps, OwnProps >(
	mapStateToProps,
	{ setValuePW, calcTankVol, setFilter }
  )( PodgrzewanieWodyComponent );
import { SET_FILTER, wynik, GET_PRODUCTS_BEGIN, GET_PRODUCTS_SUCCESS, SET_VALUE_WYNIK, GET_PRODUCTS_FAILURE, FILTER_PRODUCTS, GET_SELECTION_BEGIN, GET_SELECTION_FAILURE } from '../actionTypes/wynikTypes';
import { NotificationManager } from 'react-notifications';

// import _ from 'lodash';

const filters = {
    mode: 'monoenergetyczny',
    type: '',
    series: [],
    heaterPower: [],
    heaterVoltage: null,
    voltage: null,
    powerOutput: null,
    seckondHeaterPower: null,
    buildInTank: null,
    tankCapacity: null,
    outputTemp: 45,
    outsideTemp: 0,
    bivalentTemp: null,
    extrapolatedPowerOutput: null,
    waterHeating: true
}



const initialState:wynik = {
    mode: '',
    type: '',
    series: [],
    // selectedProduct: initialProductList[0],
    selectedProductName: '',
    productList: { outdoorProducts: [], indoorProducts: []},
    filteredProductList: [],
    filters: filters
}

//     outdoorProducts: {};
//     indoorProducts: {};
//     productList: Product[];
//     selectedProduct?: Product;
//     outputTemp: number;

export function wynikReducer(state = initialState, action: any ) {
    switch(action.type) {
        case SET_FILTER: {
            const { filter, value } = action.payload;
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [filter]: value
                }
            }
        }
        case SET_VALUE_WYNIK: {
            const { key, value } = action.payload;
            return {
                ...state,
                [key]: value
            }
        }
        case GET_PRODUCTS_BEGIN: {
            return {
                ...state,
                loading: true,
                error: null
            }
        }
        case GET_PRODUCTS_FAILURE: {
            NotificationManager.error('Nie udało się pobrać produktów z serwera.');
            return {
                ...state,
                loading: true,
                error: null
            }
        }
        case GET_PRODUCTS_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: null,
                productList: action.payload,
                // selectedProduct: action.payload[0],
                // selectedProductName: action.payload[0] ? action.payload[0].name : '',
            }
        }
        case GET_SELECTION_BEGIN: {
            return {
                ...state,
                loading: true,
                error: null
            }
        }
        case GET_SELECTION_FAILURE: {
            NotificationManager.error('Nie udało się pobrać doboru z serwera.');
            return {
                ...state,
                loading: false,
                error: null
            }
        }
        case FILTER_PRODUCTS: {
            const selProd = state.selectedProduct
            const selProdName = state.selectedProductName
            return {
                ...state,
                filteredProductList: action.payload,
                selectedProduct: selProd ? selProd : action.payload[0],
                selectedProductName: selProdName ? selProdName : action.payload[0]?.name
            }
        }
        default: {
            return state
        }
    }
}
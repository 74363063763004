import React from 'react';
import SVG from 'react-inlinesvg';
import clsx from 'clsx';


interface MMIconProps {
        name?: any,
        size?: any,
        color?: any,
        add?: any,
        className?: any,
        alt?: string
}

export const MMIcon = ({ name, size, color, add, className, ...props }: MMIconProps ) => {
        return (
                <div className={clsx(
                        'inline',
                )}>
                        <SVG
                                src={process.env.PUBLIC_URL + '/img/' + name + '.svg'}
                                alt={name}
                                className={clsx(
                                        'inline',
                                        'fill-current',
                                        'm-auto',
                                        size === 'big' ? ['w-12', 'h-12'] : size === 'small' ? ['w-6', 'h-6'] : ['w-8', 'h-8'],
                                        color === 'red' ? 'text-red-600' : color === "blue" ? 'text-blue-500' : color === 'green' ? 'text-green-500' : color, 
                                        className
                                ) }
                                { ...props }
                        />
                </div>
        )
};


import { LatLngTuple, LatLng } from 'leaflet';


export interface Location {
    climateZone?: String | null,
    coords?: LatLngTuple | LatLng | null,
    temp?: number | undefined, 
    address: string
}

export const SET_LOCATION = 'SET_LOCATION';
export interface SetLocation {
    type: typeof SET_LOCATION,
    payload: Location
}

export const SET_ZONE = 'SET_ZONE';
export interface SetZone {
    type: typeof SET_ZONE,
    payload: Location
}

export const SET_ADDRESS = 'SET_ADDRESS';
export interface SetAddress {
    type: typeof SET_ADDRESS,
    payload: string
}
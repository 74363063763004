import React from 'react';
import { Product } from '../../../pompFinder/pompFinder';
import './smallProductDescr.css';
import Button from '@material-ui/core/Button';

interface OwnProps {
    product: Product,
    onClick: any,
    className?: string,
    buildingThermalLoad: number, 
    mode: string,
    outsideTemp: number
}

export default function SmallProductDesc(props: OwnProps) {
    const { product, className, onClick, buildingThermalLoad, mode, outsideTemp } = props;
    const extrapolatedPower =  Math.round( product.extrapolatedPower as number * 10) / 10;
    let pompPercent = Math.round( extrapolatedPower / buildingThermalLoad * 100 );
    if(pompPercent > 100) pompPercent = 100;
    // const heaterPercent = Math.round( product.inHeater as number / buildingThermalLoad * 100 );
    const heaterPercent = 100 - pompPercent;


    return (
        <Button style={{ padding: 0, width: '100%'}} key={'smallProductDesc' + product.name } >
            <div 
                className={ className }
                onClick={ onClick }
            >
                <h5 className="wynik-products-productCard-heading">{ product.name }</h5>
                <div className="wynik-products-productCard-desc-container">
                    <div className="wynik-products-productCard-desc-col">
                        Moc pompy: <span>{ extrapolatedPower }&nbsp;kW</span><br />
                        { product.inHeater ? <>Moc grzałki: <span>{ product.inHeater }&nbsp;kW</span></> : 'Brak grzałki' }<br />
                        W temp.: <span>{ outsideTemp }°C</span><br />
                        <div style={{marginLeft: '10px'}}>
                            % pracy pompy: <span>{ pompPercent }&nbsp;%</span><br />
                            { mode === 'monoenergetyczny' && product.inHeater ? <>% pracy grzałki: <span>{ heaterPercent }&nbsp;%</span><br /></> : '' }
                        </div>
                    </div>
                    <div className="wynik-products-productCard-desc-col">
                        Tryb chłodzenia: <span>{ product.inCooling && product.outCooling ? 'TAK' : 'NIE' }</span><br />
                        Typ: <span>{ product.outMonoSplit }</span> <br />
                        Seria: <span>{ product.outSeries }</span> <br />
                        { /* eslint-disable-next-line eqeqeq */ }
                        { product.inTankCapacity != 0 ? <>Zbiornik wbudowany: <span>{ product.inBuildInTank ? 'TAK' : 'NIE' }</span><br /></> : '' }
                        Zasilanie: <span>{ product.outVoltage }&nbsp;V</span><br />
                    </div>
                </div>
            </div>
        </Button>
    )
}

// name: string,
// outName: string,
// outDesc: string,
// outMonoSplit: string,
// outSeries: string,
// outBuildInTank: boolean,
// outTankCapacity: number,
// outOperatingModes: string[],
// outVoltage: number,
// max: MaxMin,
// min: MaxMin,
// inName?: string,
// inDesc?: string,
// inHeater?: number,
// inCylinderHydrobox?: string,
// inVoltage?: number,
// inBuildInTank?: boolean,
// inTankCapacity?: number,
// extrapolatedPower?: number
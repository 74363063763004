import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import produkty from '../../img/produkty.png';
import ecodan from '../../img/ecodan.svg';
import './start.css';
import {
	Link,
  } from "react-router-dom";

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { MMButton } from '../common/MMButton';
import clsx from 'clsx';


export default function Start() {

    const [open, setOpen] = useState(true);
    const closeModal = () => setOpen(false);

    return (
        <Container fluid className="container-start" style={{ backgroundColor: 'white',  }}>
            <Row className={ 'row-start' }>
                <Col md={6} className='col-start'>
                    <h1>APLIKACJA DOBORU<br/>POMP CIEPŁA</h1>
                    <img style={{ marginBottom: '1rem' }} src={ ecodan } alt="Logo Ecodan" className="img-logo" />
                    <Link to={ "/strefa-klimatyczna" }>
                        <button className="button-rozpocznij">ROZPOCZNIJ DOBÓR<br/>POMPY CIEPŁA</button>
                    </Link>
                </Col>
                <Col md={6} className='col-start'>
                    <img src={ produkty } alt="Pompy ciepła" className="img-produkty" />
                </Col>
            </Row>
            <Popup open={ open } position="center center" closeOnDocumentClick={false}  overlayStyle={{zIndex: 1500}} contentStyle={{zIndex: 1600}}>
                    <div  className={ clsx('flex', 'flex-col', 'items-center', 'p-8', 'text-center')}>Program służy do doboru pomp ciepła Mitsubishi Electric. <br/>Wynik doboru stanowi jedynie sugestię. 
    Poprawność doboru należy każdorazowo skonsultować z projektantem instalacji oraz firmą wykonującą instalację.
                    <MMButton onClick={ closeModal } className={ clsx('w-44', 'mt-4') } color="green">Zgadzam się</MMButton>
                </div>
            </Popup>
        </Container>
    )
}




import { SET_LOCATION, SET_ZONE, Location, SET_ADDRESS } from '../actionTypes/strefaKlimatycznaTypes';
import { ActionTypes } from '../actionTypes';

export function setLocation( location: Location ): ActionTypes { 
    return {
        type: SET_LOCATION,
        payload: location
    }
}

export function setZone( location: Location ): ActionTypes { 
    return {
        type: SET_ZONE,
        payload: location
    }
}

export function setAddress( address: string ): ActionTypes {
    return {
        type: SET_ADDRESS,
        payload: address
    }
}
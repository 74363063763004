import { SET_LOCATION, SET_ZONE, SET_ADDRESS, Location, SetLocation, SetZone, SetAddress  } from '../actionTypes/strefaKlimatycznaTypes';



const initialState:Location = {
    climateZone: '',
    coords: null,
    temp: 0,
    address: '',
}

export function strefaKlimatycznaReducer(state = initialState, action: SetLocation | SetZone | SetAddress) {
    switch(action.type) {
        case SET_LOCATION: {
            const { coords, climateZone, temp } = action.payload;
            return {
                ...state,
                coords: coords ? coords : state.coords,
                climateZone: climateZone ? climateZone : state.climateZone,
                temp: temp ? temp : state.temp
            }
        }
        case SET_ZONE: {
            const { coords, climateZone, temp } = action.payload;
            return {
                ...state,
                coords: coords ? coords : state.coords,
                climateZone: climateZone ? climateZone : state.climateZone,
                temp: temp ? temp : state.temp
            }
        }
        case SET_ADDRESS: {
            return {
                ...state, 
                address: action.payload
            }
        }
        default: {
            return state
        }
    }
}
import './App.css';
import StepperMenu from './components/StepperMenu';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import React from 'react';

declare module "@material-ui/core/styles/createPalette" {
	interface Palette {
	  'tertiary': Palette['primary'];
	}
	interface PaletteOptions {
	  tertiary: PaletteOptions['primary'];
	}
}

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: '',
      main: '#f50f10',
      // dark: '',
      contrastText: ''
    },
    secondary: {
      // light: '#d4d4d4',
      main: '#707070',
      // dark: '',
      contrastText: '#fff'
    },
    tertiary: {
      main: '#d4d4d4',
      contrastText: '#fff'
    }
  },
  overrides: {
    MuiTextField: {
      root: {
        width: '220px',
        inputProps: {
          style: {
            padding: '2px'
          }
        }
      }
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
    },
  },
});


function App() {
  return (
    <ThemeProvider theme={ theme }>
      <header className="App-header">
        <StepperMenu />
      </header>
      <NotificationContainer />
    </ThemeProvider>
  );
}

export default App;

export interface podgrzewanieWody {
    waterHeating: boolean,
    selectionMethod?: 'habitants' | 'volume',
    habitantsNo: number,
    waterUsage: number,
    buildinTank?: boolean,
    tankWaterTemp: number,
    tankVolume?: number
    outputWaterVol?: number
    tapTemp: number,
    tankVolumeCalculator: number
}

export const SET_VALUE_PW = 'SET_VALUE_PW';
export interface setValuePW {
    type: typeof SET_VALUE_PW,
    payload: {
        element: String | any,
        value: boolean | number | undefined
    }
}

export const CALC_TANK_VOL = 'CALC_TANK_VOL';
export interface calcTankVol {
    type: typeof CALC_TANK_VOL,
}
import { Product } from '../../pompFinder/pompFinder';

export interface wynik {
    mode: string,
    type: string,
    series: string[],
    selectedProduct?: Product,
    selectedProductName: string,
    productList: {outdoorProducts: [], indoorProducts: []},
    filteredProductList: Product[],
    filters: {
        mode: string,
        type: string,
        series: string[],
        heaterPower: number[],
        heaterVoltage: number | null,
        voltage: number | null,
        powerOutput: number | null,
        seckondHeaterPower: number | null,
        buildInTank: boolean | null,
        tankCapacity: number | null,
        outputTemp: number | null,
        bivalentTemp: number | null,
        extrapolatedPowerOutput: number | null,
        waterHeating: boolean,
    }
}

export const SET_FILTER = 'SET_FILTER';
export interface SetFilter {
    type: typeof SET_FILTER,
    payload: {
        filter: String | any,
        value: string | [] | {}
    }
}

export const SET_VALUE_WYNIK = 'SET_VALUE_WYNIK';
export interface SetValueWynik {
    type: typeof SET_VALUE_WYNIK,
    payload: {
        key: String | any,
        value: string | [] | {}
    }
}

export const GET_PRODUCTS_BEGIN = 'GET_PRODUCTS_BEGIN';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE';

export const GET_SELECTION_BEGIN = 'GET_SELECTION_BEGIN';
export const GET_SELECTION_SUCCESS = 'GET_SELECTION_SUCCESS';
export const GET_SELECTION_FAILURE = 'GET_SELECTION_FAILURE';

export const SAVE_SELECTION_BEGIN = 'SAVE_SELECTION_BEGIN';
export const SAVE_SELECTION_SUCCESS = 'SAVE_SELECTION_SUCCESS';
export const SAVE_SELECTION_FAILURE = 'SAVE_SELECTION_FAILURE';

export const FILTER_PRODUCTS = 'FILTER_PRODUCTS';
export interface FilterProducts {
    type: typeof FILTER_PRODUCTS,
    payload: Product[]
}
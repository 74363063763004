import React from 'react';



interface OwnProps {
    filters: any
}

export default function EmptyResult(props: OwnProps) {
    const { filters } = props;
    return (
        <div style={{ textAlign: 'center', width: '100%', }}>
            Niestety nie znaleźliśmy produktu spełniającego wybrane kryteria :( <br /> 
            Spróbuj obniżyć temperaturę zasilania, wybrać zbiornik ciepłej wody użytkowej o innej pojemności. Rozważ przeprowadzenie termomodernizacji budynku w celu zmniejszenia wymaganej mocy grzewczej.
            { filters.outputTemp && filters.outputTemp <= 25 ? 'Spróbuj zwiększyć temperaturę zasilania w danych instalacji' : '' }
        </div>
    )
}


// mode: string | null, 
// type: string | null,
// series: string[],
// voltage: number | null, 
// powerOutput: number | null,
// seckondHeaterPower: number | null,
// buildInTank: boolean | null,
// tankCapacity: number | null,
// outputTemp: number | null,
// outsideTemp: number | null,
// bivalentTemp: number | null,
// extrapolatedPowerOutput: number | null,
import { TOGGLE_RECIVER, SET_TEMP, SET_OTHER_RECIVER_NAME } from '../actionTypes/daneInstalacjiTypes';
import { ActionTypes } from '../actionTypes';



export function setTemp( element: string, temp: number ):ActionTypes {
    return {
        type: SET_TEMP,
        payload: {
            element: element,
            temp: temp
        }
    }
}

export function toggleReciver(element: String, value: Boolean ):ActionTypes {
    return {
        type: TOGGLE_RECIVER,
        payload: {
            element: element,
            value: value
        }
    }
}


export function setOtherReciverName( name: string ):ActionTypes {
    return {
        type: SET_OTHER_RECIVER_NAME,
        payload: name
    }
}
import { PDFDocument } from "pdf-lib";
import RaportPdf from "./raportPdf";
import { pdf } from "@react-pdf/renderer";
import FileSaver from "file-saver";

export default async function GluePdf(props) {
  const { strefaKlimatyczna, szacowanieMocy, daneInstalacji, podgrzewanieWody, wynik } = props;
  if (!wynik.selectedProduct) return false;
  const { outName, inName } = wynik.selectedProduct;

  const outPdfPath = process.env.REACT_APP_API_PUBLIC_ADDRESS + "./OutdoorUnitPdfs/" + outName + ".pdf";
  const inPdfPath = process.env.REACT_APP_API_PUBLIC_ADDRESS + "./indoorUnitPdfs/" + inName + ".pdf";
  const ecodanPdfPath = process.env.REACT_APP_API_PUBLIC_ADDRESS + "./Ecodan.pdf";
  const p1 = process.env.PUBLIC_URL + "/pdf_templates/p1.pdf";
  const pLast = process.env.PUBLIC_URL + "/pdf_templates/p-last.pdf";

  const finalPdfDoc = await PDFDocument.create();

  // first page
  const p1PdfBytes = await fetch(p1).then((res) => res.arrayBuffer());
  const p1PdfDoc = await PDFDocument.load(p1PdfBytes);
  const [p1Page] = await finalPdfDoc.copyPages(p1PdfDoc, [0]);
  finalPdfDoc.addPage(p1Page);

  // selection data
  const selectionDataPart = (
    <RaportPdf
      strefaKlimatyczna={strefaKlimatyczna}
      szacowanieMocy={szacowanieMocy}
      daneInstalacji={daneInstalacji}
      podgrzewanieWody={podgrzewanieWody}
      wynik={wynik}
    />
  );
  const selectionDatablob = await pdf(selectionDataPart).toBlob();
  const selectionDataArrBuffer = await selectionDatablob.arrayBuffer();
  const selectionDataPdfDoc = await PDFDocument.load(selectionDataArrBuffer);
  const selectionDataPages = await finalPdfDoc.copyPages(selectionDataPdfDoc, selectionDataPdfDoc.getPageIndices());
  selectionDataPages.forEach((page) => finalPdfDoc.addPage(page));

  // ecodan card
  const zaletyEcodanPdfBytes = await fetch(ecodanPdfPath)
    .then((res) => {
      if (res.status !== 200) {
        return false;
      }
      return res.arrayBuffer();
    })
    .catch();
  if (zaletyEcodanPdfBytes) {
    const zaletyEcodanPdfDoc = await PDFDocument.load(zaletyEcodanPdfBytes);
    const zaletyEcodanPages = await finalPdfDoc.copyPages(zaletyEcodanPdfDoc, zaletyEcodanPdfDoc.getPageIndices());
    zaletyEcodanPages.forEach((page) => finalPdfDoc.addPage(page));
  }

  // outside unit card
  const kartaOutPdfBytes = await fetch(outPdfPath)
    .then((res) => {
      if (res.status !== 200) {
        return false;
      }
      return res.arrayBuffer();
    })
    .catch();
  if (kartaOutPdfBytes) {
    const kartaOutPdfDoc = await PDFDocument.load(kartaOutPdfBytes);
    const kartaOutPages = await finalPdfDoc.copyPages(kartaOutPdfDoc, kartaOutPdfDoc.getPageIndices());
    kartaOutPages.forEach((page) => finalPdfDoc.addPage(page));
  }

  // inside unit card
  const kartaInPdfBytes = await fetch(inPdfPath)
    .then((res) => {
      if (res.status !== 200) {
        return false;
      }
      return res.arrayBuffer();
    })
    .catch();
  if (kartaInPdfBytes) {
    const kartaInPdfDoc = await PDFDocument.load(kartaInPdfBytes);
    const kartaInPages = await finalPdfDoc.copyPages(kartaInPdfDoc, kartaInPdfDoc.getPageIndices());
    kartaInPages.forEach((page) => finalPdfDoc.addPage(page));
  }

  // last page
  const pLastPdfBytes = await fetch(pLast).then((res) => {
    if (res.status !== 200) {
      return false;
    }
    return res.arrayBuffer();
  });
  const pLastPdfDoc = await PDFDocument.load(pLastPdfBytes);
  const [pLastPages] = await finalPdfDoc.copyPages(pLastPdfDoc, pLastPdfDoc.getPageIndices());
  finalPdfDoc.addPage(pLastPages);

  return finalPdfDoc.save().then((pdf) => {
    FileSaver.saveAs(new Blob([pdf], { type: "data:application/pdf;base64" }), wynik.selectedProductName + "-Ecodan-raport.pdf", { autoBom: true });
  });
}

import React from 'react';
import clsx from 'clsx';

export const MMButton = (props) => {
    const { children, onClick, inverted, color, className, size, ...otherProps } = props;

    const txtCl         = color === 'blue' ? 'text-blue-500' : color === 'green' ? 'text-green' : 'text-red';
    const hoverTxtCl    = color === 'blue' ? 'hover:text-blue-500' : color === 'green' ? 'hover:text-green' : 'hover:text-red';
    const bgCl          = color === 'blue' ? 'bg-blue-500' : color === 'green' ? 'bg-green' : 'bg-red';
    const hoverBgCl     = color === 'blue' ? 'hover:bg-blue-500' : color === 'green' ? 'hover:bg-green' : 'hover:bg-red';
    const borderCl      = color === 'blue' ? 'border-blue-500' : color === 'green' ? 'border-green' : 'border-red';
    const paddingX       = size === 'small' ? 'px-3' : 'px-8';
    const paddingY       = size === 'small' ? 'py-1' : 'py-1';
    const fontSize       = size === 'small' ? 'text-xs' : '';
    return <button 
                onClick={onClick} 
                className={ clsx(
                    'rounded-lg',
                    paddingX, paddingY,
                    'border-2', 'border-solid', borderCl,
                    'font-light',
                    'transition-all',
                    fontSize,
                    inverted ? 'bg-white' : bgCl,
                    inverted ? txtCl : 'text-white',
                    inverted ? 'border-opacity-100' : 'border-opacity-0',
                    inverted ? 'hover:text-white' : hoverTxtCl,
                    inverted ? hoverBgCl : 'hover:bg-white',
                    inverted ? 'hover:border-opacity-0' : 'hover:border-opacity-100',
                    className
                ) }
                { ...otherProps }
            >
                { children }
            </button>
}

import React, { ChangeEvent, useRef, useEffect } from 'react';
import { connect } from "react-redux";
import { setLocation, setZone, setFilter, setAddress } from '../../../redux/actions';
import { Location } from '../../../redux/actionTypes/strefaKlimatycznaTypes';
import climateZones from '../../constants/climateZones.json';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Container, Row, Col } from 'react-bootstrap';
import 'leaflet/dist/leaflet.css';
import 'leaflet/dist/images/marker-icon.png';
import 'leaflet/dist/images/marker-shadow.png';
import { MapContainer, Polygon, Marker, Popup, useMap, useMapEvents } from 'react-leaflet';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import 'leaflet-boundary-canvas';
import granicePolski from './granicePolski.json';

import { calcThermalLoad } from '../../../redux/actions/szacowanieMocyActions';

import gju from 'geojson-utils';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
	iconSize: [25, 41],
	iconAnchor: [15, 41],
	popupAnchor: [2, -40],
});

L.Marker.prototype.options.icon = DefaultIcon;




let timeout: any;


// get points for climateZones
// let tempAreaPoints: any[] = [];
interface OwnProps {
    setActiveStep: Function
}

interface DispatchProps {
	setZone: Function, 
	setLocation: Function, 
	setFilter: Function,
	calcThermalLoad: Function,
	setAddress: Function
}

type Props = OwnProps & DispatchProps & Location;

const useStyles = makeStyles({
	lubWpisz: {
		fontSize: '0.8rem',
		textAlign: 'right',
		marginRight: '0.5rem',
		paddingTop: '7px'
	},
	kodPocztowy: {

	}
})

function StrefaKlimatyczna(props: Props) { 
	const { setLocation, setZone, setFilter, temp, calcThermalLoad, setActiveStep, setAddress, coords } = props;
	const classes = useStyles();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		setActiveStep(1)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	const mapRef = useRef<HTMLDivElement>(null);




	const [locationFound, setLocationFound ] = React.useState(false);
	function LocationMarker(props: any) {
		const { coords, setLocation,  } = props;		
		const map = useMap();

		const tileLayerCreator:any = L.TileLayer;
		const boundaryLayer = new tileLayerCreator.boundaryCanvas('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
			boundary: granicePolski.geometries[0],
			attribution: 'Map data &copy; 2021 OpenStreetMap contributors',
		});

		map.addLayer(boundaryLayer);

		useEffect(() => {
			if(!locationFound) {
				setLocationFound(true);
				map.locate({
					setView: true,
					maxZoom: 11,
					watch: false,
				});
			}

			if(coords) {
				map.setView(coords, map.getZoom() );

				climateZones.forEach(zone => {
					if(zone.area.length === 2) {
						zone.area.forEach( innerZone => {
							const inPoly = gju.pointInPolygon({
								type: 'Point',
								coordinates: [Number(coords.lat), Number(coords.lng)]
							}, {
								"type": "Polygon",
								"coordinates": [ innerZone ]
							})
							if(inPoly) {
								setZone({ 
									climateZone: zone.name,
									temp: zone.temp
								});
							}
						})

					} else {
						const inPoly = gju.pointInPolygon({
							type: 'Point',
							coordinates: [Number(coords.lat), Number(coords.lng)]
						}, {
							"type": "Polygon",
							"coordinates": zone.area
						})
						if(inPoly) {
							setZone({ 
								climateZone: zone.name,
								temp: zone.temp
							});
						}
					}

			})

		}

	});

		useMapEvents({
			click(e) {

				map.stopLocate();

				// get points for climateZones
				// tempAreaPoints.push([e.latlng]);
				// let stringTempPoints = tempAreaPoints.map( (point: any) => {
				// 	return [point[0].lat, point[0].lng];
				// })


				const inPoly = gju.pointInPolygon({
					type: 'Point',
					coordinates: [e.latlng.lat, e.latlng.lng]
				}, {
					"type": "Polygon",
					"coordinates": [[[54.457266680933856,18.687744140625004],[54.66112372206639,18.511962890625004],[54.62297813269033,18.885498046875004],[54.80068486732236,18.775634765625004],[54.88924640307589,18.094482421875004],[54.67383096593114,16.776123046875004],[54.3549556895541,16.051025390625004],[53.98193516209167,14.161376953125],[52.908902047770255,14.007568359375],[51.631657349449995,14.622802734375],[50.80593472676908,14.908447265625002],[50.30337575356313,16.248779296875004],[50.205033264943346,17.237548828125004],[50.10648772767332,17.611083984375004],[49.937079756975294,18.138427734375004],[49.66762782262194,18.577880859375004],[49.48240137826932,18.841552734375],[49.36806633482156,19.127197265625004],[49.453842594330744,19.412841796875004],[49.32512199104003,19.698486328125004],[49.1242192485914,19.830322265625004],[49.15296965617042,20.335693359375004],[49.36806633482156,20.665283203125004],[49.31079887964633,21.346435546875004],[49.081062364320736,22.159423828125004],[48.99463598353408,23.060302734375004],[49.52520834197442,22.884521484375004],[50.3734961443035,23.829345703125004],[50.387507803003146,24.136962890625004],[50.666872321810715,24.224853515625004],[50.86144411058926,24.268798828125004],[51.08282186160978,24.093017578125004],[51.767839887322154,23.697509765625004],[51.90361280788357,23.807373046875004],[52.17393169256849,23.719482421875],[52.32191088594773,23.367919921875004],[52.56299503955803,23.609619140625004],[52.61639023304539,23.939208984375004],[52.855864177853995,24.114990234375004],[53.26521293124656,24.049072265625],[54.278054859672835,23.521728515625004],[54.49556752187409,22.906494140625004],[54.584796743678744,19.698486328125004],[54.49556752187409,19.302978515625],[54.482804559582554,18.885498046875004]]]
				})

				if(!inPoly) {
					setLocation({
						climateZone: '',
						coords: null,
						temp: 0,
					});
				} else {
					setLocation({ 
						coords: e.latlng,
					});
				}


			},
			// locationfound() { },
		})
	
		return coords === null ? null : (
			<Marker position={ coords }>
				<Popup>Wybrana lokalizacja</Popup>
			</Marker>
		)
	}


	function mapStateToProps(store: any) {
		const { strefaKlimatycznaReducer } = store;
		return strefaKlimatycznaReducer;
	}

	const ConnectedLocationMarker = connect( mapStateToProps, { setLocation, setZone })(LocationMarker);



    const controller = new AbortController();
	const signal = controller.signal;

	const [postCode, setPostCode ] = React.useState('');
    const handlePostCodeChange = (event:  ChangeEvent<HTMLInputElement>, setLocation: Function, mapRef:any) => {

		setPostCode( event.currentTarget.value );
		// controller.abort();
		const val = event.currentTarget.value;

		function getLocationFromPostCode(val: string) {

			fetch('https://nominatim.openstreetmap.org/search?q=' + val + '&countrycodes=pl&format=json', {
				method: 'get',
				signal: signal,
			})
				.then( res => res.json())
				.then( data => {
					setLocation({ 
						coords: {
							lat: data[0].lat, 
							lng: data[0].lon
						}
					});
					// mapRef.current.leafletElement.setView({
					// 	lat: data[0].lat, 
					// 	lng: data[0].lon
					// }, 11 );

				})
				.catch( err => {
					console.log(err);
				})
		}

		
		clearTimeout(timeout);
		timeout = setTimeout( getLocationFromPostCode, 1000, val);
    };

   
    useEffect( () => {
		setFilter('outsideTemp', temp);
		calcThermalLoad(temp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setFilter, temp]);

	useEffect( () => {
		if(coords) {
			const latlng: any = coords;
			reverseGeocode(latlng.lat as any, latlng.lng as any)
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [coords])


	function reverseGeocode(lat: string, lng: string) {

		fetch('https://nominatim.openstreetmap.org/reverse?lat=' + lat + '&lon=' + lng + '&format=jsonv2', {
			method: 'get',
			signal: signal,
		})
			.then( res => res.json())
			.then( data => {
				const address = data;
				setAddress(address);
			})
			.catch( err => {
				// console.log(err);
			})
	}

    return (
        <Container className="MuiPaper-elevation2" style={{
			paddingBottom: '52px',
			marginBottom: '52px',
			marginTop: '1rem',
			paddingTop: '2rem',
			backgroundColor: '#EFEFEF',
			borderRadius: '5px',
		}}>
			<Row style={{
				marginBottom: '1.5rem'
			}}>
				<Col>
					<h3>Wybierz lokalizację instalacji na mapie</h3>
				</Col>
				<Col style={{
					display: 'flex',
					justifyContent: 'flex-end'
				}}>
					<div style={{ display: 'flex' }}>
						<p className={classes.lubWpisz}>Podaj kod pocztowy lub<br />nazwę miejscowości</p>
						<TextField 
								id="post-code" 
								label="Lokalizacja" 
								variant="outlined" 
								className={classes.kodPocztowy}
								value={ postCode } 
								onChange={(e:any) => handlePostCodeChange(e, setLocation, mapRef) } 
								type="text" 
								InputLabelProps={{
									shrink: true,
								}}
							/>
					</div>
				</Col>
			</Row>
			<Row>
				<Col>
					<MapContainer style={{ width: '100%', height: '500px' }} center={ [52.303, 18.007] } zoom={6} scrollWheelZoom={true}>

						{/* <TileLayer
							attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
							url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
							/> */}
						{ climateZones.map( zone => {
							return (
								<Polygon 
									key={zone.name}
									positions={ zone.area as [number, number][] | [number, number][][] } 
									// pathOptions={{ color: '#' + (Math.floor(Math.random()*16777215).toString(16)) }} 
									pathOptions={{ color: 'transparent' }} 
									eventHandlers={{
										click: () => {
											// console.log('polygon clicked')
											setZone({ 
												climateZone: zone.name,
												temp: zone.temp
											});
										}
									}}
								/>
							)
						})}

						<ConnectedLocationMarker />
					</MapContainer>
				</Col>
			</Row>
        </Container>
    );
}

function mapStateToProps(store: any) {
    const { strefaKlimatycznaReducer } = store;
    return strefaKlimatycznaReducer;
}


export default connect<Location, DispatchProps, OwnProps>(
	mapStateToProps,
	{ setLocation, setZone, setFilter, calcThermalLoad, setAddress }
  )( StrefaKlimatyczna );



// sposób doboru: standard wykonania budynku | moc pompy ciepła | Charakterystyka energetyczna
export enum selectionMethod { 
    standardBudynku = "Standard wykonania budynku", 
    mocPompy = "Moc pompy ciepła",
    charakterystyka = "Charakterystyka energetyczna",
}

// standardBudynku
export interface BuildingStandard {
    name: string,
    desc: string
    group: string,
    value: number,
    units: string
}

// typ piętra
export enum floorType {
    floor = 'Pietro',
    attic = 'Poddasze',
    basement = 'Piwnica',
    firstFloor = 'Parter'
}

// redux store object structure
export interface SzacowanieMocy {
    selectionMethod?: string | null | false,
    buildingStandard?: BuildingStandard,
    // temperatura utrzymywana w budynku (20 st. domyślnie) [Tzad]
    tempInBuilding: number,
    // wydajność izolacji [Wi][W/m3K]
    insulationPerformance?: number,
    // wskaźnik nieodnawialnej energii pierwotnej [Ep]
    primodalEnergy?: number,
    // projektowe obciązenie cieplne budynku [Qg][kW]
    buildingThermalLoad?: number,
    // graniczna temperatura ogrzewania [Tg][st. C]
    heatingLimitTemp?: Number,
    // ogrzewana kubatura budynku
    buildingCubature?: number,
    // piętra 
    floors: {
        [id: number]: { 
            floorType: any,
            area?: number,
            height?: number,
            floorCubature?: number,
        }
    },
    addFloorType: string,
    showHide: {
        tempInBuilding: boolean,
        cubatureCalculator: boolean,
        buildingArea: boolean
    },
    buildingArea: number,

}



export const SET_SELECTION_METHOD = 'SET_SELECTION_METHOD';
export interface SetSelectionMethod {
    type: typeof SET_SELECTION_METHOD,
    payload: selectionMethod
}

export const SET_BUILDING_STANDARD = 'SET_BUILDING_STANDARD';
export interface SetBuildingStandard {
    type: typeof SET_BUILDING_STANDARD,
    payload: BuildingStandard
}

export const SET_PRIMODAL_ENERGY = 'SET_PRIMODAL_ENERGY';
export interface setPrimodalEnergy {
    type: typeof SET_PRIMODAL_ENERGY,
    payload: Number
}

export const SET_INSULATION_PERFORMANCE = 'SET_INSULATION_PERFORMANCE';
export interface setInsulationPerformance {
    type: typeof SET_INSULATION_PERFORMANCE,
    payload: Number
}

export const SHOW_HIDE = 'SHOW_HIDE';
export interface setShowHide {
    type: typeof SHOW_HIDE,
    payload: {
        element: String | any,
        value: Boolean
    }
}

export const SET_VALUE = 'SET_VALUE';
export interface setValue {
    type: typeof SET_VALUE,
    payload: {
        element: String | any,
        value: Boolean
    }
}

export const SET_FLOOR_VALUE = 'SET_FLOOR_VALUE';
export interface setFloorValue {
    type: typeof SET_FLOOR_VALUE,
    payload: {
        id: Number | String | any,
        element: String | any,
        value: String | Number
    }
}

export const ADD_FLOOR = 'ADD_FLOOR';
export interface addFloor {
    type: typeof ADD_FLOOR,
}

export const REMOVE_FLOOR = 'REMOVE_FLOOR';
export interface removeFloor {
    type: typeof REMOVE_FLOOR,
    payload: {
        id: Number | String | any,
    }
}

export const CALCULATE_BUILDING_CUBATURE = 'CALCULATE_BUILDING_CUBATURE';
export interface calculateBuildingCubature {
    type: typeof CALCULATE_BUILDING_CUBATURE,
}

export const CALC_THERMAL_LOAD = 'CALC_THERMAL_LOAD';
export interface calcThermalLoad {
    type: typeof CALC_THERMAL_LOAD,
    payload: {
        outsideTemp: number
    }
}
import { SET_VALUE_PW, CALC_TANK_VOL } from '../actionTypes/podgrzewanieWodyTypes';
import { ActionTypes } from '../actionTypes';

export function setValuePW(element: String, value: any ): ActionTypes {
    return {
        type: SET_VALUE_PW,
        payload: {
            element: element,
            value: value
        }
    }
}

export function calcTankVol(): ActionTypes {
    return {
        type: CALC_TANK_VOL
    }
}
import React, { useEffect, useState } from 'react';
import { connect } from "react-redux";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from '@material-ui/core/Button';
// import Tooltip from '@material-ui/core/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import { setTemp, toggleReciver, setOtherReciverName, setFilter } from '../../../redux/actions';
import { daneInstalacji } from '../../../redux/actionTypes/daneInstalacjiTypes';
import * as pompFinder from '../../../pompFinder/pompFinder';
import './daneInstalacji.css';
import clsx from 'clsx';
import { MMIcon } from '../../common/MMIcon';

interface OwnProps {
    setActiveStep: Function
}

interface DispatchProps {
    setTemp: Function,
    toggleReciver: Function,
    setOtherReciverName: Function,
    setFilter: Function
}

type Props = OwnProps & DispatchProps & daneInstalacji;

function DaneInstalacjiComponent(props: Props) { 

    const { 
        setTemp, 
        toggleReciver, 
        setOtherReciverName,
        selectedRecivers,
        ogrzewaniePodlogoweTemp,
        grzejnikiPlytoweTemp,
        klimakonwerteryTemp,
        otherReciverTemp,
        otherReciverName,
        setFilter, 
        setActiveStep
    } = props;
    
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => setActiveStep(3), []);


    useEffect( () => {
        const temps = [ogrzewaniePodlogoweTemp, grzejnikiPlytoweTemp, klimakonwerteryTemp, otherReciverTemp];
        
        const outputTemp = pompFinder.findOutputTemp(selectedRecivers, temps);

        setFilter('outputTemp', outputTemp);

    }, [ogrzewaniePodlogoweTemp, grzejnikiPlytoweTemp, klimakonwerteryTemp, otherReciverTemp, selectedRecivers, setFilter]);

    const [warningMsg, setWarningMsg] = useState('');
    useEffect( () => {
        if(otherReciverTemp >= 50 || grzejnikiPlytoweTemp >= 50) {
            setWarningMsg('Wysoka temperatura zasilania powoduje wzrost zużycia energii elektrycznej co skutkuje zmniejszeniem efektywności systemu ogrzewania pompą ciepła. W zależności od temperatury zewnętrznej może to powodować załączanie grzałki elektrycznej w celu zapewnienia zadanej temperatury. Sugerowane jest obniżenie temp. zasilania lub kontakt z projektantem instalacji/firmą wykonującą instalację.');
        } else {
            setWarningMsg('');
        }
    }, [otherReciverTemp, grzejnikiPlytoweTemp])


    return (
        <Container className="MuiPaper-elevation2" style={{
			paddingTop: '15px',
			paddingBottom: '15px',
			marginBottom: '52px',
			marginTop: '1rem',
			backgroundColor: '#EFEFEF',
			borderRadius: '5px',
		}}>
            <Container className={ clsx( 'szacowanie-container', '' ) } style={{
                marginTop: '0',
                marginBottom: '0'
            }}>
            <Row>
                <Col>
                    <p>Kliknij, aby wybrać rodzaj odbiorników ciepła (max. 2)</p>
                    <div className="odbiorniki-ciepla">
                        <Button 
                            variant="outlined"
                            className={ selectedRecivers.ogrzewaniePodlogowe ? 'selected' : '' }
                            onClick={ () => {
                                toggleReciver('ogrzewaniePodlogowe', !selectedRecivers.ogrzewaniePodlogowe);
                            }}
                        >Ogrzewanie podłogowe</Button>
                        <FormControl style={{marginLeft: '1rem'}}>
                            <Select
                                labelId="tempPlaszczyznowe-select-label"
                                id="tempPlaszczyznowe-select"
                                value={ ogrzewaniePodlogoweTemp }
                                onChange={
                                    (e) => setTemp( 'ogrzewaniePodlogoweTemp', e.target.value )
                                }
                            >
                                <MenuItem value={25}>25 <sup>o</sup>C/20 <sup>o</sup>C</MenuItem>
                                <MenuItem value={30}>30 <sup>o</sup>C/25 <sup>o</sup>C</MenuItem>
                                <MenuItem value={35}>35 <sup>o</sup>C/30 <sup>o</sup>C</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="odbiorniki-ciepla">
                        <Button 
                            variant="outlined"
                            className={ selectedRecivers.grzejnikiPlytowe ? 'selected' : '' }
                            onClick={ () => {
                                toggleReciver('grzejnikiPlytowe', !selectedRecivers.grzejnikiPlytowe);
                            }}
                        >Grzejniki płytowe</Button>
                        <FormControl style={{marginLeft: '1rem'}}>
                            <Select
                                labelId="tempPlytowe-select-label"
                                id="tempPlytowe-select"
                                value={grzejnikiPlytoweTemp}
                                onChange={
                                    (e) => setTemp( 'grzejnikiPlytoweTemp', e.target.value )
                                }
                            >
                                <MenuItem value={45}>45 <sup>o</sup>C/35 <sup>o</sup>C</MenuItem>
                                <MenuItem value={55}>55 <sup>o</sup>C/45 <sup>o</sup>C</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="odbiorniki-ciepla">
                        <Button 
                            variant="outlined"
                            className={ selectedRecivers.klimakonwertery ? 'selected' : '' }
                            onClick={ () => {
                                toggleReciver('klimakonwertery', !selectedRecivers.klimakonwertery);
                            }}
                        >KLIMAKONWEKTORY</Button><p style={{marginLeft: '1rem', color: 'rgb(183 183 183 / 87%)'}}>45 <sup>o</sup>C/35 <sup>o</sup>C</p>
                    </div>
                    <div className="odbiorniki-ciepla" >
                        <Button 
                            variant="outlined"
                            className={ selectedRecivers.otherReciver ? 'selected' : '' }
                            onClick={ () => {
                                toggleReciver('otherReciver', !selectedRecivers.otherReciver);
                            }}
                        >Inny odbiornik</Button>
                        { selectedRecivers.otherReciver ? 
                        <div className="daneInstalacji-innny-inputs-container">
                            <TextField 
                                id="outlined-basic" 
                                label="Nazwa" 
                                variant="outlined" 
                                className="daneInstalacji-inny-inputs"
                                value={ otherReciverName }
                                onChange={ (e) => setOtherReciverName( e.target.value ) } 
                                type="text" 
                                style={{ marginLeft: '1rem'}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                            <TextField 
                                id="outlined-basic" 
                                label="Temperatura zasilania" 
                                variant="outlined" 
                                value={ otherReciverTemp }
                                onChange={ (e) => setTemp( 'otherReciverTemp', e.target.value ) } 
                                type="number" 
                                style={{ marginLeft: '1rem'}}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">°C</InputAdornment>,
                                    inputProps: { 
                                        min: 25,
                                        max: 60
                                    },
                                }}
                            />
                        </div>
                        : '' }
                    </div>
                    { warningMsg !== '' ? <div className={clsx('mt-4')}><MMIcon name="exclamation-mark" size="small" className={clsx('mb-4')} /><p className={ clsx('mt-1') }>{ warningMsg }</p></div> : '' }
                </Col>
            </Row>
            </Container>
        </Container>

    )
}

function mapStateToProps(store: any) {
    const { daneInstalacjiReducer } = store;
    return daneInstalacjiReducer;
}

export default connect<daneInstalacji, DispatchProps, OwnProps>(
	mapStateToProps,
	{ setTemp, toggleReciver, setOtherReciverName, setFilter }
  )( DaneInstalacjiComponent );
import { SET_SELECTION_METHOD, floorType, SzacowanieMocy, SET_BUILDING_STANDARD, SET_PRIMODAL_ENERGY, SET_INSULATION_PERFORMANCE, SHOW_HIDE, SET_VALUE, SET_FLOOR_VALUE, ADD_FLOOR, REMOVE_FLOOR, CALCULATE_BUILDING_CUBATURE, CALC_THERMAL_LOAD } from '../actionTypes/szacowanieMocyTypes';
// import { ActionTypes } from '../actionTypes';
import { SetSelectionMethod, SetBuildingStandard, setPrimodalEnergy, setInsulationPerformance, setShowHide, setValue, setFloorValue, addFloor, removeFloor, calculateBuildingCubature, calcThermalLoad } from '../actionTypes/szacowanieMocyTypes';
import * as pompFinder from '../../pompFinder/pompFinder';
import _ from 'lodash';

type ActionTypes = SetSelectionMethod | SetBuildingStandard | setPrimodalEnergy | setInsulationPerformance | setShowHide | setValue | setFloorValue | addFloor | removeFloor | calculateBuildingCubature | calcThermalLoad;

const initialState:SzacowanieMocy = {
    selectionMethod: false,
    buildingStandard: undefined,
    // temperatura utrzymywana w budynku (20 st. domyślnie) [Tzad]
    tempInBuilding: 20,
    // wydajność izolacji [Wi][W/m3K]
    insulationPerformance: undefined,
    // wskaźnik nieodnawialnej energii pierwotnej [Ep]
    primodalEnergy: undefined,
    // projektowe obciązenie cieplne budynku [Qg][kW]
    buildingThermalLoad: 0,
    // graniczna temperatura ogrzewania [Tg][st. C]
    heatingLimitTemp: 0,
    // ogrzewana kubatura budynku
    buildingCubature: undefined,
    // piętra 
    floors: {
        1: { 
            floorType: floorType.firstFloor,
            area: undefined,
            height: undefined,
            floorCubature: undefined,
        }
    },
    addFloorType: 'attic',
    showHide: {
        cubatureCalculator: false,
        tempInBuilding: false,
        buildingArea: false
    },
    buildingArea: 0,
}

export function szacowanieMocyReducer(state = initialState, action: ActionTypes) {
    switch(action.type) {
        case SET_SELECTION_METHOD: {
            return {
                ...state,
                selectionMethod: action.payload
            }
        }
        case SET_BUILDING_STANDARD: {
            return {
                ...state,
                buildingStandard: action.payload
            }
        }
        case SET_PRIMODAL_ENERGY: {
            return {
                ...state,
                primodalEnergy: action.payload
            }
        }
        case SET_INSULATION_PERFORMANCE: {
            return {
                ...state,
                insulationPerformance: action.payload
            }
        }
        case SHOW_HIDE: {
            const { element, value } = action.payload;
            if(element === 'all') {
                return {
                    ...state,
                    showHide: {
                        tempInBuilding: value,
                        cubatureCalculator: value,
                        buildingArea: value
                    }
                }
            }
            return {
                ...state,
                showHide: {
                    ...state.showHide,
                    [element]: value
                }
            }
        }
        case SET_VALUE: {
            const { element, value } = action.payload;
            return {
                ...state, 
                [element]: value
            }
        }
        case SET_FLOOR_VALUE: {
            const { id, element, value } = action.payload;
            return {
                ...state, 
                floors: {
                    ...state.floors,
                    [id]: {
                        ...state.floors[id],
                        [element]: value
                    }
                }
            }
        }
        case ADD_FLOOR: {
            const lastId = Object.keys(state.floors).map( id => parseInt(id) );
            const newId = Math.max( ...lastId ) + 1;
            return {
                ...state, 
                floors: {
                    ...state.floors,
                    [newId]: {
                        floorType: state.addFloorType,
                        area: null,
                        height: undefined,
                        floorCubature: undefined,
                    }
                }
            }
        }
        case REMOVE_FLOOR: {
            const { id } = action.payload;
            const newFloors = _.omit(state.floors, [id]);
            return {
                ...state, 
                floors: newFloors
            }
        }
        case CALCULATE_BUILDING_CUBATURE: {
            const { floors } = state;
            let buildingCubature = 0;
            let buildingArea = 0;
            for (const floor in floors) {
                if (Object.prototype.hasOwnProperty.call(floors, floor)) {
                    const element = floors[floor];
                    if( element.floorCubature ) {
                        if(element.floorType === 'Parter' || element.floorType === 'floor' || element.floorType === 'attic') {
                            buildingCubature += element.floorCubature;
                            if(element.area) buildingArea += Number(element.area);
                        }
                        if(element.floorType === 'basement') {
                            buildingCubature += (element.floorCubature/3);
                            if(element.area) buildingArea += Number(element.area)/3;
                        }
                    }
                }
            }
            buildingCubature = Math.ceil(buildingCubature);
            buildingArea = Math.ceil(buildingArea);
            return {
                ...state,
                buildingCubature: buildingCubature,
                buildingArea: buildingArea
            }
        }
        case CALC_THERMAL_LOAD: {
            const { outsideTemp } = action.payload;
            const { buildingCubature, insulationPerformance, tempInBuilding, buildingThermalLoad, buildingArea, primodalEnergy, heatingLimitTemp, buildingStandard, selectionMethod } = state;
            let newBuildingThermalLoad = buildingThermalLoad ? buildingThermalLoad : 0;
            if((buildingStandard && buildingStandard.units === 'primodalEnergy' && selectionMethod === 'standardBudynku') || selectionMethod === 'charakterystyka') {
                if(buildingArea && primodalEnergy ) {
                    let time;
                    switch (outsideTemp) {
                        case -16:
                            time = 2050;
                            break;
                        case -18:
                            time = 1950;
                            break;
                        case -20:
                            time = 1800;
                            break;
                        case -22:
                            time = 1550;
                            break;
                        case -24:
                            time = 1450;
                            break;
                        default:
                            time = 1800;
                            break;
                    }
                    if(selectionMethod === 'charakterystyka') {
                        time = 1800;
                    }
                    newBuildingThermalLoad = (buildingArea * primodalEnergy) / time;
                }
            } else {
                if(buildingCubature && insulationPerformance && tempInBuilding) {
                    if(selectionMethod === 'standardBudynku') {
                        newBuildingThermalLoad = buildingCubature * insulationPerformance * (tempInBuilding-outsideTemp) / 1000;
                        const points = [
                            -20,
                            Number(newBuildingThermalLoad),
                            Number(heatingLimitTemp),
                            0
                        ]
                        newBuildingThermalLoad = pompFinder.extrapolateValue(points, outsideTemp);
                    } else {
                        newBuildingThermalLoad = buildingCubature * insulationPerformance * (tempInBuilding-outsideTemp) / 1000;
                    }
                }
            }
            return {
                ...state, 
                buildingThermalLoad: Math.round(newBuildingThermalLoad * 10) / 10
            }
        }

        default: {
            return state
        }
    }
}
import React, { useRef, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Chart from "chart.js";
import _ from "lodash";
import "./wynik.css";

import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import Tooltip from "@material-ui/core/Tooltip";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Slider from "@material-ui/core/Slider";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";

import { wynik } from "../../../redux/actionTypes/wynikTypes";
import { setFilter, getProducts, setValueWynik, filterProducts } from "../../../redux/actions";
import SmallProductDesc from "./SmallProductDescr";
import EmptyResult from "./EmptyResulta";
import * as pompFinder from "../../../pompFinder/pompFinder";
import { Product } from "../../../pompFinder/pompFinder";
import clsx from "clsx";
import TechnologyDescr from "./technologyDescr";
import { MMIcon } from "../../common/MMIcon";

interface OwnProps {
  setActiveStep: Function;
}

interface DispatchProps {
  setFilter: Function;
  getProducts: Function;
  setValueWynik: Function;
  filterProducts: Function;
}

type Props = OwnProps & DispatchProps & wynik;

function WynikComponent(props: Props) {
  const { setFilter, setValueWynik, selectedProductName, productList, filteredProductList, selectedProduct, filterProducts, filters, setActiveStep } =
    props;

  const { mode, type, series, outputTemp, powerOutput, bivalentTemp, extrapolatedPowerOutput, voltage, heaterPower, heaterVoltage } = filters;
  let timeout: any;

  const chartRef = useRef(null);

  const outsideTemp = useSelector((state: any) => state.strefaKlimatycznaReducer.temp);
  const heatingLimitTemp = useSelector((state: any) => state.szacowanieMocyReducer.heatingLimitTemp);
  const buildingThermalLoad = useSelector((state: any) => state.szacowanieMocyReducer.buildingThermalLoad);

  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => setActiveStep(5), []);

  const [myChart, setMyChart] = useState<any>();

  function createChart(chartRef: any, myChart: any) {
    if (!outputTemp) return;
    if (!selectedProduct) return;

    const labels = [-30, -28, -25, -20, -15, -10, -7, 2, 7, 12, 15, 20];

    const pompPower: any = pompFinder.getPompPowerArrsByTemp(selectedProduct as Product, outputTemp);
    let pompPowerMax: any[] = pompPower.max;
    let pompPowerMin: any[] = pompPower.min;

    let insertIndexOutsideTemp: number;
    if (!labels.includes(outsideTemp)) {
      insertIndexOutsideTemp = _.sortedIndex(labels, outsideTemp);
      labels.splice(insertIndexOutsideTemp, 0, outsideTemp);
      pompPowerMax.splice(insertIndexOutsideTemp, 0, null);
      pompPowerMin.splice(insertIndexOutsideTemp, 0, null);
    } else {
      insertIndexOutsideTemp = labels.indexOf(outsideTemp);
    }

    let insertIndexheatingLimitTemp: number;
    if (!labels.includes(heatingLimitTemp)) {
      insertIndexheatingLimitTemp = _.sortedIndex(labels, heatingLimitTemp);
      labels.splice(insertIndexheatingLimitTemp, 0, heatingLimitTemp);
      pompPowerMax.splice(insertIndexheatingLimitTemp, 0, null);
      pompPowerMin.splice(insertIndexheatingLimitTemp, 0, null);
    } else {
      insertIndexheatingLimitTemp = labels.indexOf(heatingLimitTemp);
    }

    let pompPowerWithHeater: any;
    if (mode === "monoenergetyczny") {
      pompPowerWithHeater = pompPowerMax.map((power) => {
        if (!power) return null;
        return Number(power) + Number(selectedProduct.inHeater);
      });
      pompPowerWithHeater = pompFinder.transoformPowerArrToPoints(pompPowerWithHeater, labels);
    } else if (mode === "biwalentnyRownolegly") {
      pompPowerWithHeater = pompPowerMax.map((power) => {
        if (!power) return null;
        return Number(power) + Number(filters.seckondHeaterPower);
      });
      pompPowerWithHeater = pompFinder.transoformPowerArrToPoints(pompPowerWithHeater, labels);
    }

    let buildingThermalLoadData: any = labels.map((label, i) => {
      if (i === insertIndexOutsideTemp) return powerOutput;
      if (i === insertIndexheatingLimitTemp) return 0;
      return null;
    });

    pompPowerMax = pompFinder.transoformPowerArrToPoints(pompPowerMax, labels);
    pompPowerMin = pompFinder.transoformPowerArrToPoints(pompPowerMin, labels);
    buildingThermalLoadData = pompFinder.transoformPowerArrToPoints(buildingThermalLoadData, labels);

    let bivalentPoint: any;
    if (mode === "biwalentnyAlternatywny") {
      bivalentPoint = [
        {
          x: Number(bivalentTemp),
          y: Number(extrapolatedPowerOutput),
        },
        {
          x: Number(bivalentTemp),
          y: 0,
        },
      ];
    } else if (mode === "monoenergetyczny" || mode === "biwalentnyRownolegly") {
      const crossPoint = pompFinder.findIntersectionPointFromPower(buildingThermalLoadData, pompPowerMax);
      if (crossPoint) {
        if (bivalentTemp !== crossPoint.x) {
          setFilter("bivalentTemp", crossPoint.x);
        }
        bivalentPoint = [
          {
            x: Number(crossPoint.x),
            y: Number(crossPoint.y),
          },
          {
            x: Number(crossPoint.x),
            y: 0,
          },
        ];
      }
    }

    const context2d = chartRef.current.getContext("2d");
    const dataObj = {
      type: "line",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Max. moc pompy ciepła",
            data: pompPowerMax,
            backgroundColor: ["rgba(75, 192, 192, 0.2)"],
            borderColor: ["rgba(255, 99, 132, 1)"],
            borderWidth: 1,
            lineTension: 0,
            spanGaps: true,
          },
          {
            label: "Min. moc pompy ciepła",
            data: pompPowerMin,
            backgroundColor: ["#4caf502b"],
            borderColor: ["#4caf50"],
            borderWidth: 1,
            lineTension: 0,
            spanGaps: true,
          },
          {
            label: "Obciążenie cieplne budynku",
            data: buildingThermalLoadData,
            backgroundColor: ["#002bff2b"],
            borderColor: ["#002bff"],
            fill: false,
            borderWidth: 1,
            lineTension: 0,
            spanGaps: true,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        devicePixelRatio: 4,
        scales: {
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Moc [kW]",
              },
            },
          ],
          xAxes: [
            {
              type: "linear",
              ticks: {
                min: -30,
                max: 25,
                // stepSize: 1,
              },
              scaleLabel: {
                display: true,
                labelString: "Temperatura zewnętrzna [°C]",
              },
            },
          ],
        },
        animation: {
          onComplete: function () {
            const aa: any = this;
            setValueWynik(
              "canvasImg",
              aa.toBase64Image({
                pixelRatio: 2,
              })
            );
          },
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItem: any, data: any) {
              var label = data.datasets[tooltipItem.datasetIndex].label || "";

              if (label) {
                label += ": ";
              }
              label += Math.round(tooltipItem.yLabel * 10) / 10;
              return label;
            },
            title: function (tooltipItem: any): any {
              return Math.round(tooltipItem[0].xLabel * 10) / 10 + " °C";
            },
          },
        },
      },
    };

    const bivalentObj = {
      label: "Punkt biwalentny",
      data: bivalentPoint,
      backgroundColor: ["transparent"],
      borderColor: ["#000"],
      borderWidth: 1,
      lineTension: 0,
      spanGaps: true,
    };
    if (bivalentPoint) dataObj.data.datasets.push(bivalentObj);

    const seckondHeaterObj = {
      label: mode === "monoenergetyczny" ? "Moc pompy ciepła z grzałką" : "Moc pompy ciepła z drugim źródłem",
      data: pompPowerWithHeater,
      backgroundColor: ["rgba(110, 25, 0, 0.1)"],
      borderColor: ["rgb(110, 25, 0)"],
      borderWidth: 1,
      lineTension: 0,
      spanGaps: true,
    };
    if (mode === "monoenergetyczny" || mode === "biwalentnyRownolegly") dataObj.data.datasets.push(seckondHeaterObj);

    if (myChart) myChart.destroy();
    const a = new Chart(context2d, dataObj);
    setMyChart(a);
  }

  // function valuetext(value: number) {
  //     return `${value}°C`;
  // }

  // useEffect( () => {
  //     filterProducts(filters);
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (productList.outdoorProducts.length) {
      filterProducts(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, type, series, voltage, productList, heaterPower, heaterVoltage]);

  useEffect(() => {
    createChart(chartRef, myChart);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct]);

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div
      style={{
        maxWidth: "90vw",
        height: "100%",
      }}
      className="wynik-filtry-drawer-container"
    >
      <h4 style={{ textAlign: "center", fontWeight: 300 }}>Filtruj</h4>
      <div className="filtry">
        {/*
                    <p className="wynik-filtry-nazwa-p">Typ</p>
                    <ToggleButtonGroup 
                        value={ type } 
                        className="wynik-filtry-buttonGroup"
                        onChange={ (e, val) => {
                            setFilter('type', val);
                        }} 
                        aria-label="Typ pompy" 
                        exclusive 
                        style={{width: '100%'}}
                        >
                        <ToggleButton value="mono" aria-label="Monoblok" style={{width: '32%'}} className="wynik-filtry-button">
                            <Tooltip title="Jedno urządzenie">
                                    <div>Monoblok</div>
                            </Tooltip>
                        </ToggleButton>
                        <ToggleButton value="split" aria-label="Split" style={{width: '32%'}} className="wynik-filtry-button">
                            <Tooltip title="Dwa urządzenia">
                                    <div>Split</div>
                            </Tooltip>
                        </ToggleButton>
                        <ToggleButton value="grunt" aria-label="Gruntowa" style={{width: '32%'}} className="wynik-filtry-button">
                            <Tooltip title="Dwa urządzenia">
                                    <div>Gruntowa</div>
                            </Tooltip>
                        </ToggleButton>
                    </ToggleButtonGroup>
                    */}

        <p className="wynik-filtry-nazwa-p">Seria</p>
        <ToggleButtonGroup
          className="wynik-filtry-buttonGroup"
          value={series}
          onChange={(e, val) => {
            setFilter("series", val);
          }}
          aria-label="Typoszereg"
          style={{ width: "100%" }}
        >
          <ToggleButton value="zubadan" aria-label="Zubadan Inverter" style={{ width: "49%" }} className="wynik-filtry-button">
            <Tooltip title="">
              <div>Zubadan Inverter</div>
            </Tooltip>
          </ToggleButton>

          {/*
                        <ToggleButton value="power" aria-label="Power Inverter" style={{width: '32%'}} className="wynik-filtry-button">
                            <Tooltip title="">
                                    <div>Power Inverter</div>
                            </Tooltip>
                        </ToggleButton>
                        */}
          <ToggleButton value="r290" aria-label="R290 Inverter" style={{width: '32%'}} className="wynik-filtry-button">
            <Tooltip title="">
              <div>R290 Inverter</div>
            </Tooltip>
          </ToggleButton>

          <ToggleButton value="eco" aria-label="Eco Inverter" style={{ width: "49%" }} className="wynik-filtry-button">
            <Tooltip title="">
              <div>Eco Inverter</div>
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>

        <p className="wynik-filtry-nazwa-p">Zasilanie</p>
        <ToggleButtonGroup
          className="wynik-filtry-buttonGroup"
          value={voltage}
          onChange={(e, val) => {
            setFilter("voltage", val);
          }}
          aria-label="Napięcie"
          style={{ width: "100%" }}
          exclusive
        >
          <ToggleButton value={230} aria-label="230 V" style={{ width: "49%" }} className="wynik-filtry-button">
            <Tooltip title="">
              <div>
                Jedna faza
                <br />
                (230 V)
              </div>
            </Tooltip>
          </ToggleButton>
          <ToggleButton value={400} aria-label="400 V" style={{ width: "49%" }} className="wynik-filtry-button">
            <Tooltip title="">
              <div>
                Trzy fazy
                <br />
                (400 V)
              </div>
            </Tooltip>
          </ToggleButton>
        </ToggleButtonGroup>

        <hr className={clsx("mt-3")} />
        <div className={clsx("flex", "items-baseline", "cursor-pointer")} onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}>
          <div className={clsx("transition-all", "delay-300")} style={{ transform: showAdvancedFilters ? "rotate(180deg)" : "" }}>
            <MMIcon name="expand" size="small" color="text-gray-400" />
          </div>
          <h4 className={clsx("mt-2", "ml-2", "text-gray-400")}>Filtry zaawansowane</h4>
        </div>

        <div className={clsx("transition-all", "delay-500", "overflow-hidden", showAdvancedFilters ? "h-36" : "h-0")}>
          <p className="wynik-filtry-nazwa-p">Moc grzałki elektrycznej</p>
          <ToggleButtonGroup
            className="wynik-filtry-buttonGroup"
            value={heaterPower}
            onChange={(e, val) => {
              setFilter("heaterPower", val);
            }}
            aria-label="Moc grzałki elektrycznej"
            style={{ width: "100%" }}
          >
            <ToggleButton value={2} aria-label="2 kW" style={{ width: "49%" }} className="wynik-filtry-button">
              <Tooltip title="">
                <div>2 kW</div>
              </Tooltip>
            </ToggleButton>
            <ToggleButton value={6} aria-label="6 kW" style={{ width: "49%" }} className="wynik-filtry-button">
              <Tooltip title="">
                <div>6 kW</div>
              </Tooltip>
            </ToggleButton>
            <ToggleButton value={9} aria-label="9 kW" style={{ width: "49%" }} className="wynik-filtry-button">
              <Tooltip title="">
                <div>9 kW</div>
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>

          <p className="wynik-filtry-nazwa-p">Zasilanie grzałki elektrycznej</p>
          <ToggleButtonGroup
            className="wynik-filtry-buttonGroup"
            value={heaterVoltage}
            onChange={(e, val) => {
              setFilter("heaterVoltage", val);
            }}
            aria-label="Zasilanie grzałki elektrycznej"
            style={{ width: "100%" }}
            exclusive
          >
            <ToggleButton value={230} aria-label="230 V" style={{ width: "49%" }} className="wynik-filtry-button">
              <Tooltip title="">
                <div>230 V</div>
              </Tooltip>
            </ToggleButton>
            <ToggleButton value={400} aria-label="400 V" style={{ width: "49%" }} className="wynik-filtry-button">
              <Tooltip title="">
                <div>400 V</div>
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>

      <div
        className={clsx("MuiPaper-elevation2", "wynik-products-container")}
        style={{
          marginTop: "0.4rem",
          marginBottom: "0",
          marginLeft: "-15px",
          marginRight: "0",
          padding: "0.5rem",
          backgroundColor: "#fff",
          borderRadius: "5px",
          width: "calc(100% + 30px)",
          height: "calc(100% - 225px)",
          overflowY: "auto",
          flex: 1,
        }}
      >
        <div style={{ height: '600px' }}>
          <p style={{ fontWeight: 300, fontSize: "1.6rem", lineHeight: "1.3rem", marginBottom: "1rem" }}>
            <small style={{ color: "#707070", fontSize: "0.7rem" }}>ZNALEZIONO: {filteredProductList.length}</small>
            <br />
            Wybrany produkt:{" "}
          </p>
          <div className="produkty">
            {filteredProductList.map((product, i) => {
              return (
                <SmallProductDesc
                  product={product}
                  key={product.name + i}
                  buildingThermalLoad={buildingThermalLoad}
                  outsideTemp={outsideTemp}
                  mode={mode}
                  className={clsx(selectedProductName === product.name ? "selected" : "", "wynik-products-productCard", "MuiPaper-elevation2")}
                  onClick={() => {
                    setValueWynik("selectedProductName", product.name);
                    setValueWynik("selectedProduct", product);
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
  const container = window !== undefined ? () => window.document.body : undefined;

  return (
    <Container>
      <Row>
        <Col lg={12} xl={8}>
          <div
            className="MuiPaper-elevation2"
            style={{
              paddingBottom: "15px",
              marginBottom: "52px",
              marginTop: "1rem",
              backgroundColor: "#EFEFEF",
              borderRadius: "5px",
              // marginRight: '1rem'
            }}
          >
            {/* <h4>Wybierz tryb pracy: </h4> */}
            {outputTemp && outputTemp < 50 ? (
              <ToggleButtonGroup
                value={mode}
                className="wynik-tabs-container"
                onChange={(e, val) => {
                  val && setFilter("mode", val);
                }}
                aria-label="Tryb pracy"
                exclusive
                style={{ width: "100%" }}
              >
                <ToggleButton value="monoenergetyczny" aria-label="Monoenergetyczny">
                  <Tooltip title="Pompa ciepła pokrywa część zapotrzebowania na moc grzewczą. Pozostała moc zapewniana jest przez działanie grzałki elektrycznej, wbudowanej w jednostkę wewnętrzną pompy ciepła.">
                    <div>Pompa ciepła + grzałka elektr.</div>
                  </Tooltip>
                </ToggleButton>

                <ToggleButton value="monowalentny" aria-label="Monowalentny">
                  <Tooltip title="Pompa ciepla pokrywa całkowite zapotrzebowanie na moc grzewczą, w danej strefie klimatycznej.">
                    <div>Tylko pompa ciepła</div>
                  </Tooltip>
                </ToggleButton>

                {/*
                  <ToggleButton value="biwalentnyRownolegly" aria-label="Biwalentny równoległy">
                      <Tooltip title="Pompa ciepła pokrywa część zapotrzebowania na moc grzewczą. Pozostała moc zapewniana jest przez drugie źródło ciepła np. kocioł gazowy.">
                              <div>Pompa ciepła + drugie źródło</div>
                      </Tooltip>
                  </ToggleButton>
                  <ToggleButton value="biwalentnyAlternatywny" aria-label="Biwalentny alternatywny">
                      <Tooltip title="Pompa ciepła pracuje do określonego punktu biwalentnego. Poniżej tego punktu pracę przejmuje drugie źródło ciepła np. kocioł gazowy.">
                              <div>Pompa ciepła <b>lub</b> drugie źródło</div>
                      </Tooltip>
                  </ToggleButton>
                */}
              </ToggleButtonGroup>
            ) : (
              ""
            )}

            {mode === "biwalentnyRownolegly" ? (
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1rem" }}>
                <p style={{ marginRight: "15px", marginBottom: 0 }}>Moc drugiego źródła:</p>

                <TextField
                  id="outlined-basic"
                  label="Moc"
                  variant="outlined"
                  value={filters.seckondHeaterPower}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">kW</InputAdornment>,
                  }}
                  onChange={(e) => {
                    setFilter("seckondHeaterPower", e.target.value);
                  }}
                  type="number"
                />
              </div>
            ) : (
              ""
            )}

            {mode === "biwalentnyAlternatywny" ? (
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginTop: "1rem" }}>
                Temperatura do której pompa spełnia zapotrzebowanie na moc grzewczą budynku:
                <Slider
                  style={{
                    maxWidth: "80%",
                  }}
                  defaultValue={Number(outsideTemp) - 5}
                  aria-labelledby="discrete-slider-custom"
                  step={1}
                  marks={[
                    { value: outsideTemp ? outsideTemp : -15, label: "-" + outsideTemp + "°C" },
                    { value: 0, label: "0°C" },
                    { value: heatingLimitTemp, label: heatingLimitTemp + "°C" },
                  ]}
                  min={outsideTemp ? outsideTemp : -15}
                  max={heatingLimitTemp}
                  valueLabelDisplay="auto"
                  onChange={(e, val) => {
                    clearTimeout(timeout);
                    timeout = setTimeout(() => {
                      const points = [outsideTemp, Number(powerOutput), Number(heatingLimitTemp), 0];
                      const extrapolatedThermalLoad = pompFinder.extrapolateValue(points, val as number);

                      setFilter("extrapolatedPowerOutput", extrapolatedThermalLoad);
                      setFilter("bivalentTemp", val);
                    }, 500);
                  }}
                />
              </div>
            ) : (
              ""
            )}

            {selectedProduct ? (
              <h4
                style={{
                  margin: "1rem",
                  paddingTop: "1rem",
                  fontWeight: 300,
                }}
              >
                Moc pompy vs obciążenie budynku:
              </h4>
            ) : (
              ""
            )}
            <div
              className={clsx("szacowanie-container", "")}
              style={{
                marginTop: "1rem",
                marginBottom: "0",
                marginLeft: "15px",
                marginRight: "15px",
              }}
            >
              {selectedProduct ? (
                <div style={{ marginTop: "2rem" }}>
                  <div>
                    <canvas id="chart" width="400px" height="400px" ref={chartRef}></canvas>
                  </div>
                  <TechnologyDescr tech={selectedProduct.outSeries} key={"TechnologyDescr" + selectedProduct.name} />
                  {/* <p>Moc przy temperaturze zewnętrznej równej {outsideTemp}: {selectedProduct.extrapolatedPower}</p>
                                <p>heatingLimitTemp: {heatingLimitTemp}</p>
                                <p>Pompa wyszukana dla temperatury zewnętrznej: {bivalentTemp} i wyliczonego obciążenia {extrapolatedPowerOutput}
                                </p> */}
                </div>
              ) : (
                <EmptyResult filters={filters} />
              )}
            </div>
          </div>
        </Col>

        <Col
          md={4}
          className={clsx("MuiPaper-elevation2", "wynik-col-filtry")}
          style={{
            paddingTop: "15px",
            paddingBottom: "0",
            marginBottom: "52px",
            marginTop: "1rem",
            backgroundColor: "#EFEFEF",
            borderRadius: "5px",
            height: "inherit",
          }}
        >
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden xlUp implementation="css">
            <div
              onClick={handleDrawerToggle}
              style={{
                backgroundColor: "#fff",
                borderTopLeftRadius: "5px",
                borderBottomLeftRadius: "5px",
                position: "fixed",
                right: 0,
                top: "188px",
                width: "35px",
                paddingLeft: "5px",
                height: "210px",
                cursor: "pointer",
              }}
              className="MuiPaper-elevation4"
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                className=""
                // style={{ marginLeft: '0px' }}
              >
                <FilterListIcon />
              </IconButton>
              <p
                style={{
                  transform: "rotate(90deg)",
                  minWidth: "200px",
                  marginLeft: "-87px",
                  marginTop: "79px",
                }}
              >
                Wybierz inny produkt
              </p>
            </div>
            <Drawer
              container={container}
              variant="temporary"
              anchor={"right"}
              open={mobileOpen}
              onClose={handleDrawerToggle}
              classes={
                {
                  // paper: classes.drawerPaper,
                }
              }
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>

          <div style={{ height: "100%" }} className="height100">
            <Hidden lgDown implementation="css">
              {drawer}
            </Hidden>
          </div>
        </Col>
      </Row>
      <Row style={{ height: "300px" }}></Row>
    </Container>
  );
}

function mapStateToProps(store: any) {
  const { wynikReducer } = store;
  return wynikReducer;
}

export default connect<wynik, DispatchProps, OwnProps>(mapStateToProps, { setFilter, getProducts, setValueWynik, filterProducts })(WynikComponent);

import { daneInstalacji, TOGGLE_RECIVER, SET_TEMP, SET_OTHER_RECIVER_NAME, ToggleReciver, SetTemp, SetOtherReciverName } from '../actionTypes/daneInstalacjiTypes';

// import _ from 'lodash';


const initialState:daneInstalacji = {
    selectedRecivers: {
        ogrzewaniePodlogowe: false,
        grzejnikiPlytowe: false,
        klimakonwertery: false,
        otherReciver: false,
    },
    ogrzewaniePodlogoweTemp: 35,
    grzejnikiPlytoweTemp: 45,
    klimakonwerteryTemp: 45,
    otherReciverTemp: 0,
    otherReciverName: ''
}


export function daneInstalacjiReducer(state = initialState, action:  ToggleReciver | SetTemp | SetOtherReciverName ) {
    switch(action.type) {
        case SET_OTHER_RECIVER_NAME: {
            return {
                ...state,
                otherReciverName: action.payload
            }
        }
        case TOGGLE_RECIVER: {
            const { element, value } = action.payload;

            // make sure no more than 2 is selected
            let disabledReciver = '';
            if(value) {
                const { selectedRecivers } = state;
                let selectedReciversNo = 0;
                Object.entries(selectedRecivers).forEach( ( [reciver, reciverVal] ) => {
                    if(reciverVal) selectedReciversNo++;
                    if(selectedReciversNo > 1 && disabledReciver === '') {
                        disabledReciver = reciver;
                    }
                })
            }
            if(element === 'all') {
                return {
                    ...state,
                    selectedRecivers: {
                        ogrzewaniePodlogowe: value,
                        grzejnikiPlytowe: value,
                        klimakonwertery: value,
                        otherReciver: value,
                    },
                }
            } else if(disabledReciver) {
                return {
                    ...state,
                    selectedRecivers: {
                        ...state.selectedRecivers,
                        [disabledReciver]: false,
                        [element]: value
                    }
                }
            } else {
                return {
                    ...state,
                    selectedRecivers: {
                        ...state.selectedRecivers,
                        [element]: value
                    }
                }
            }
        }
        case SET_TEMP: {
            const { element, temp } = action.payload;
            return {
                ...state, 
                [element]: temp
            }
        }
        
        default: {
            return state
        }
    }
}
import React, { useEffect } from 'react';
import { connect } from "react-redux";
import { setValuePW } from '../../../redux/actions';
import { podgrzewanieWody } from '../../../redux/actionTypes/podgrzewanieWodyTypes';

import Col from 'react-bootstrap/Col';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';

import pompaFoto from '../../../img/p21_cylinder.png';
import infoIcon from '../../../img/info-circle-solid.svg';
import bath from '../../../img/bath.png';
import { toInteger } from 'lodash';
import './podgrzewanieWody.css';
import clsx from 'clsx';
import { NotificationManager } from 'react-notifications';

interface OwnProps {

}

interface DispatchProps {
    setValuePW: Function,
}

type Props = OwnProps & DispatchProps & podgrzewanieWody;

function KalkulatorObjetosci(props: Props) {
    const {
        tankWaterTemp,
        tankVolumeCalculator,
        outputWaterVol,
        setValuePW,
        tapTemp
    } = props;


    useEffect(() => {
        setValuePW( 'outputWaterVol', ((tankVolumeCalculator * (tankWaterTemp-10))/(tapTemp-10)) );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tankVolumeCalculator, tankWaterTemp, tapTemp])

    return (
        <Col style={{ background: '#707070', padding: '1rem', color: 'white !important', borderRadius: '5px' }} className="cieplaWoda-kalkulator-container">
                
            <h4 style={{ color: '#fff'}}><img src={ infoIcon } alt="Pompa ciepła" width="22" style={{ marginRight: '0.5rem'}} />Podpowiedź</h4>
            <p style={{ color: '#fff', lineHeight: '100%', fontSize: '0.9rem'}}>Dzięki mieszaniu wody o wysokiej temperaturze np. 50°C z wodą wodociągową, ilość dostępnej ciepłej wody dla domowników jest większa niż pojemność zbiornika w urządzeniu.</p>
            <div className="cieplaWoda-kalkulator-images-container" >
                <div style={{ position: 'relative'}}  className="cieplaWoda-kalkulator-pompa-container">
                    <img src={ pompaFoto } alt="Pompa ciepła" width="150" style={{
                        position: 'relative',
                        
                    }} />
                    <TextField 
                        className={ clsx("cieplaWoda-podpowiedz-inputs", 'cieplaWoda-kalkulator-input-tankWaterTemp') }
                        id="tankWaterTemp" 
                        label="Temp. ciepłej wody" 
                        variant="outlined" 
                        value={ tankWaterTemp }
                        onChange={ (e) => {
                            const tankWaterTemp = toInteger(e.target.value);
                            if(tankWaterTemp < tapTemp) {
                                NotificationManager.warning('Temperatura ciepłej wody w zasobniku nie może być niższa niż temperatura wody w kranie.');
                            }
                            setValuePW( 'tankWaterTemp', tankWaterTemp );
                            if(tankVolumeCalculator) {
                                setValuePW( 'outputWaterVol', ((tankVolumeCalculator * (tankWaterTemp-10))/(tapTemp-10)) );
                            }
                        }}
                        InputProps={{
                            inputProps: { 
                                min: tapTemp < 40 ? 40 : tapTemp,
                                max: 60
                            },
                            endAdornment: <InputAdornment position="end">°C</InputAdornment>
                        }}
                        error={ tankWaterTemp < 0 }
                        helperText={ tankWaterTemp < 0 ? "Wprowadź dodatnią wartość." : '' }
                        type="number"
                    />

                    <FormControl style={{ borderRadius: '5px', position: 'absolute', left: 0, top: '210px' }}>
                        <InputLabel id="addFloor-select-label" style={{ padding: '9px' }}>Pojemność zasobnika</InputLabel>
                        <Select
                            labelId="tankVolume-select-label"
                            className={ clsx("tankVolume-select-label", 'mt-8') }
                            
                            id="tankVolume" 
                            value={ tankVolumeCalculator }
                            onChange={ (e) => {
                                const tankVolumeCalculator = toInteger(e.target.value);
                                setValuePW( 'tankVolumeCalculator', tankVolumeCalculator );
                                if(tankWaterTemp) {
                                    setValuePW( 'outputWaterVol', ((tankVolumeCalculator * (tankWaterTemp-10))/(tapTemp-10)) );
                                }
                            }}
                        >
                            <MenuItem value={ 170 }>170 l</MenuItem>
                            <MenuItem value={ 200 }>200 l</MenuItem>
                            <MenuItem value={ 300 }>300 l</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div style={{ position: 'relative'}} className="cieplaWoda-kalkulator-wanna-container">
                    <img src={ bath } alt="Wanna" className="podgrzewanieWody-kalkulator-wanna" />        
                    <TextField 
                        className={ clsx("cieplaWoda-podpowiedz-inputs", 'cieplaWoda-kalkulator-input-tapTemp') }
                        id="tapTemp" 
                        label="Temperatura w kranie" 
                        variant="outlined" 
                        value={ tapTemp }
                        onChange={ (e) => {
                            const tapTemp = toInteger(e.target.value);
                            setValuePW( 'tapTemp', tapTemp );
                            if(tankWaterTemp < tapTemp) {
                                NotificationManager.warning('Temperatura wody w kranie nie może być wyższa niż ciepłej wody w zasobniku .');
                            }
                            if(tankWaterTemp) {
                                setValuePW( 'outputWaterVol', ((tankVolumeCalculator * (tankWaterTemp-10))/(tapTemp-10)) );
                            }
                        }}
                        InputProps={{
                            inputProps: { 
                                min: 0,
                                max: tankWaterTemp > 60 ? 60 : tankWaterTemp 
                            },
                            endAdornment: <InputAdornment position="end">°C</InputAdornment>
                        }}
                        error={ tapTemp < 0 }
                        helperText={ tapTemp < 0 ? "Wprowadź dodatnią wartość." : '' }
                        type="number"
                    />
                </div>
            </div>

            {/* <img src={ kran } alt="Zasobnik" width="100"/> */}
            <p style={{ color: '#fff', marginTop: '2rem'}}>Ilość ciepłej wody dostępnej dla domowników: <span style={{}}>{ ( outputWaterVol ? Math.round((outputWaterVol ) * 10) / 10 : '') } l</span></p>
        </Col>
    )
}

function mapStateToProps(store: any) {
    const { podgrzewanieWodyReducer } = store;
    return podgrzewanieWodyReducer;
}

export default connect<podgrzewanieWody, DispatchProps, OwnProps >(
	mapStateToProps,
	{ setValuePW }
  )( KalkulatorObjetosci );
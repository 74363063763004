import React, { useEffect, useState } from 'react';
import { connect, useSelector } from "react-redux";
import { NotificationManager } from 'react-notifications';
import { saveSelection } from '../../../redux/actions';
import { SzacowanieMocy } from '../../../redux/actionTypes/szacowanieMocyTypes';
import { InstallersMap } from './InstallersMap';

import gluePdf from './gluePdf';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Button from '@material-ui/core/Button';
import _ from 'lodash';

// import RaportPdf from './raportPdf';
// import { PDFViewer } from '@react-pdf/renderer';



interface OwnProps {
    setActiveStep: Function,
    completedSteps: { [k: number]: boolean },
}

interface DispatchProps {
    saveSelection: Function,
}

type Props = OwnProps & DispatchProps & SzacowanieMocy;



function RaportComponent(props: Props) {
    const { saveSelection, setActiveStep, completedSteps } = props;

    const daneInstalacji = useSelector( (state:any) => state.daneInstalacjiReducer);
    const podgrzewanieWody = useSelector( (state:any) => state.podgrzewanieWodyReducer);
    const szacowanieMocy = useSelector( (state:any) => state.szacowanieMocyReducer);
    const strefaKlimatyczna = useSelector( (state:any) => state.strefaKlimatycznaReducer);
    const wynik = useSelector( (state:any) => state.wynikReducer);


	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => setActiveStep(6), []);

    const [pdfGenerated, setPdfGenerated] = useState(false);


    function saveAndReturnReport() {
        if(!wynik.selectedProduct) {
            NotificationManager.error('Nie wybrano żadnego produktu. Najpierw ukończ wszystkie kroki.');
            return;
        }
        saveSelection();
        return gluePdf({strefaKlimatyczna, szacowanieMocy, daneInstalacji, podgrzewanieWody, wynik}).then( res => {
            setPdfGenerated(true);
        });
    }

    function allStepsCompleted() {
        let completedCount = 0;
        _.forEach(completedSteps, val => val ? completedCount++ : null)  
        return completedCount === 6;
    }

    return (
        <Container>
            
             {/* <PDFViewer width='100%' height='800px'>
                <RaportPdf 
                    strefaKlimatyczna={ strefaKlimatyczna }
                    szacowanieMocy={ szacowanieMocy }
                    daneInstalacji={ daneInstalacji }
                    podgrzewanieWody={ podgrzewanieWody }
                    wynik={ wynik } />

            </PDFViewer>  */}

            { allStepsCompleted() || process.env.REACT_APP_ENV === 'development' ? (
                <Row>
                    <Col>
                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', height: '100%', paddingTop: '20vh', alignItems: 'center' }}>
                            <h1 style={{ textAlign: 'center', fontSize: '2rem', marginBottom: '2rem'}}>Dziękujemy za zaufanie produktom marki Mitsubishi Electric. <br />Przygotowaliśmy dla Ciebie raport w formie pliku pdf, <br/>podsumowujący Twój dobór.</h1>

                            <Button 
                                size="large" 
                                color="primary" 
                                variant="contained" 
                                style={{
                                    width: '300px',
                                    height: '80px',
                                }}
                                onClick={ saveAndReturnReport }
                            >Pobierz raport</Button>
                        </div>
                    </Col>
                </Row>
            ) : (
                <Row>
                    <Col>
                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', height: '100%', paddingTop: '20vh', alignItems: 'center' }}>
                            <h1 style={{ textAlign: 'center', fontSize: '2rem', marginBottom: '2rem'}}>Aby pobrać raport doboru, ukończ wszystkie poprzednie kroki</h1>

                        </div>
                    </Col>
                </Row>
            )}


            <InstallersMap />

            { pdfGenerated ? <div className="pdfGenerated"></div> : '' }
        </Container>
    );
}

function mapStateToProps(store: any) {
    const { szacowanieMocyReducer } = store;
    return szacowanieMocyReducer;
}

export default connect<SzacowanieMocy, DispatchProps, OwnProps >(
	mapStateToProps,
	{ saveSelection }
  )( RaportComponent );
import { SET_SELECTION_METHOD, selectionMethod, SET_BUILDING_STANDARD, BuildingStandard, SET_PRIMODAL_ENERGY, SET_INSULATION_PERFORMANCE, SHOW_HIDE, SET_VALUE, SET_FLOOR_VALUE, ADD_FLOOR, REMOVE_FLOOR, CALCULATE_BUILDING_CUBATURE, CALC_THERMAL_LOAD } from '../actionTypes/szacowanieMocyTypes';
import { ActionTypes } from '../actionTypes';



export function setSelectionMethod( selectionMethod: selectionMethod ): ActionTypes {
    return {
        type: SET_SELECTION_METHOD,
        payload: selectionMethod
    }
}

export function setBuildingStandard( buildingStandard: BuildingStandard ): ActionTypes {
    return {
        type: SET_BUILDING_STANDARD,
        payload: buildingStandard
    }
}

export function setPrimodalEnergy( primodalEnergy: Number): ActionTypes {
    return {
        type: SET_PRIMODAL_ENERGY,
        payload: primodalEnergy
    }
}

export function setInsulationPerformance( insulationPerformance: Number ): ActionTypes {
    return {
        type: SET_INSULATION_PERFORMANCE,
        payload: insulationPerformance
    }
}

export function setShowHide(element: String, value: Boolean ): ActionTypes {
    return {
        type: SHOW_HIDE,
        payload: {
            element: element,
            value: value
        }
    }
}

export function setValue(element: String, value: any ): ActionTypes {
    return {
        type: SET_VALUE,
        payload: {
            element: element,
            value: value
        }
    }
}

export function setFloorValue( id: Number, element: String, value: any ): ActionTypes {
    return {
        type: SET_FLOOR_VALUE,
        payload: {
            id: id,
            element: element,
            value: value
        }
    }
}

export function addFloor(): ActionTypes {
    return {
        type: ADD_FLOOR,
    }
}

export function removeFloor( id: Number ): ActionTypes {
    return {
        type: REMOVE_FLOOR,
        payload: {
            id: id,
        }
    }
}

export function calculateBuildingCubature(): ActionTypes {
    return {
        type: CALCULATE_BUILDING_CUBATURE
    }
}

export function calcThermalLoad( outsideTemp: number): ActionTypes {
    return {
        type: CALC_THERMAL_LOAD,
        payload: {
            outsideTemp: outsideTemp
        }
    }
}
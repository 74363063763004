import clsx from 'clsx';
import React from 'react';

import Iframe from 'react-iframe';

export const InstallersMap = () => {

    return (
            <div className={clsx('flex', 'flex-col', 'mt-12', 'items-center')}>

                <p>Poproś o radę instalatora</p>
                
                <Iframe url="https://hvacpr.pl/expo/pompa-ciepla-ecodan/mapa/djezlkxrkgotxdlfrskp"
                    width="90%"
                    height="750px"
                    id="myId"
                    className="myClassname"
                    display="initial"
                    position="relative"
                />

            </div>
        )
    }
import { SET_FILTER, GET_PRODUCTS_BEGIN, GET_PRODUCTS_SUCCESS, SET_VALUE_WYNIK, GET_PRODUCTS_FAILURE, FILTER_PRODUCTS, SAVE_SELECTION_BEGIN, SAVE_SELECTION_SUCCESS, SAVE_SELECTION_FAILURE, GET_SELECTION_BEGIN, GET_SELECTION_SUCCESS, GET_SELECTION_FAILURE } from '../actionTypes/wynikTypes';
import { ActionTypes } from '../actionTypes';
import * as pompFinder from '../../pompFinder/pompFinder';
import axios from 'axios';
import store from '../store';
import _ from 'lodash';


export function setFilter( filter: string, value: string | [] | {} ):ActionTypes {
    return {
        type: SET_FILTER,
        payload: {
            filter: filter,
            value: value
        }
    }
}

export function setValueWynik( key: string, value: string | [] | {} ):ActionTypes {
    return {
        type: SET_VALUE_WYNIK,
        payload: {
            key: key,
            value: value
        }
    }
}

export function filterProducts( filters: pompFinder.Filters ) {
    const wynikReducer:any = store.getState().wynikReducer;
    const productList = wynikReducer.productList;
    return {
        type: FILTER_PRODUCTS,
        payload: pompFinder.filterProducts( filters, productList )
    }
}

export function getProducts():any {
    return (dispatch: any) => {
        dispatch(getProductsBegin());

        return axios.get( process.env.REACT_APP_API_ADDRESS + 'products')
                .then( res => {
                    dispatch( getProductsSuccess( res.data ))
                })
                .catch( err => {
                    dispatch( getProductsFailure(err) );
                })
    }
}

export const getProductsBegin = () => ({
    type: GET_PRODUCTS_BEGIN
})

export const getProductsSuccess = (productList: pompFinder.Product[]) => {
    return {
        type: GET_PRODUCTS_SUCCESS,
        payload: productList
}}

export const getProductsFailure = (error:any) => ({
    type: GET_PRODUCTS_FAILURE,
    payload: { error }
});

export function saveSelection() {
    return (dispatch: any) => {
        dispatch(saveSelectionBegin());

        const state:any = _.cloneDeep( store.getState() );
        delete state.wynikReducer.productList;
        delete state.wynikReducer.filteredProductList;
        delete state.wynikReducer.canvasImg;
        return axios.post( process.env.REACT_APP_API_ADDRESS + 'selections', { selection: state } )
                .then( res => {
                    dispatch( saveSelectionSuccess( res.data ));
                })
                .catch( err => {
                    dispatch( saveSelectionFailure( err ) );
                })
    }
}

export const saveSelectionBegin = () => ({
    type: SAVE_SELECTION_BEGIN
})

export const saveSelectionSuccess = (res: {
    created_at: string;
    selection: any
    updated_at: string;
    _id: string;
}) => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set('id', res._id);
    window.history.pushState("","", window.location.origin + '/?' + urlParams.toString());

    return {
        type: SAVE_SELECTION_SUCCESS,
        payload: res
}}

export const saveSelectionFailure = (error:any) => ({
    type: SAVE_SELECTION_FAILURE,
    payload: { error }
});


export function getSelection( id: string ):any {
    return (dispatch: any) => {
        dispatch(getSelectionBegin());

        return axios.get( process.env.REACT_APP_API_ADDRESS + 'selections/' + id)
                .then( res => {
                    dispatch( getSelectionSuccess( res.data ))
                })
                .catch( err => {
                    dispatch( getSelectionFailure(err) );
                })
    }
}

export const getSelectionBegin = () => ({
    type: GET_SELECTION_BEGIN
})

export const getSelectionSuccess = (selection: any) => {
    return {
        type: GET_SELECTION_SUCCESS,
        payload: selection
}}

export const getSelectionFailure = (error:any) => ({
    type: GET_SELECTION_FAILURE,
    payload: { error }
});
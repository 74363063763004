import _ from 'lodash';
// import outdoorProducts from '../components/constants/outdoorProducts.json';
// import indoorProducts from '../components/constants/indoorProducts.json';
import outputTemps from '../components/constants/outputTemps.json';

export type Filters = {
    mode: string | null, 
    type: string | null,
    series: string[],
    voltage: number | null, 
    powerOutput: number | null,
    seckondHeaterPower: number | null,
    buildInTank: boolean | null,
    tankCapacity: number | null,
    outputTemp: number | null,
    outsideTemp: number | null,
    bivalentTemp: number | null,
    extrapolatedPowerOutput: number | null,
}

type MaxMin = OutputTemps

type OutputTemps = {
    "25": {[key: string]: number}, 
    "35": {[key: string]: number}, 
    "40": {[key: string]: number}, 
    "45": {[key: string]: number}, 
    "50": {[key: string]: number}, 
    "55": {[key: string]: number}, 
    "60": {[key: string]: number}, 
}


export type Product = {
    name: string,
    outName: string,
    outDesc: string,
    outMonoSplit: string,
    outSeries: string,
    outBuildInTank: boolean,
    outTankCapacity: number,
    outOperatingModes: string[],
    outVoltage: number,
    outCooling: boolean,
    outActive: boolean,
    max: MaxMin,
    min: MaxMin,
    inName?: string,
    inDesc?: string,
    inHeater?: number,
    inCylinderHydrobox?: string,
    inVoltage?: number,
    inBuildInTank?: boolean,
    inTankCapacity?: number,
    extrapolatedPower?: number,
    inMonoSplit?: string,
    inCooling?: boolean,
    inAccessories?: string,
    inAccessoriesCwu?: string,
    outAccessories?: string,
    outAccessoriesCwu?: string,
    inActive?: string,
}

export function filterProducts(filters: Filters, products: {outdoorProducts: any, indoorProducts: any}) {

    let productList: Product[] = [];
    products.outdoorProducts.forEach( (out: any ) => {

        if(out.active === false) return;

        if(out.worksWith?.length > 0) {
            let worksWithArr = out.worksWith;
            if(typeof out.worksWith === 'string' || out.worksWith instanceof String) {
                worksWithArr = out.worksWith.split(',');
            }
            worksWithArr.forEach( (indoorName: string) => {
                const indProducts = products.indoorProducts;

                const ind:any = _.find(indProducts, ['name', indoorName]);

                if(!ind) return;
                if(ind.active === false) return;

                productList.push({
                    name: out.name + ' + ' + ind.name,
                    outName: out.name,
                    outDesc: out.desc,
                    outMonoSplit: out.monoSplit,
                    outSeries: out.series,
                    outBuildInTank: out.buildInTank,
                    outTankCapacity: out.tankCapacity,
                    outOperatingModes: out.operatingModes,
                    outVoltage: out.voltage,
                    outCooling: out.cooling,
                    outAccessories: out.outAccessories,
                    outAccessoriesCwu: out.outAccessoriesCwu,
                    outActive: out.active,
                    max: out.max,
                    min: out.min,
                    inName: ind.name,
                    inDesc: ind.desc,
                    inHeater: ind.heater,
                    inCylinderHydrobox: ind.cylinderHydrobox,
                    inAccessories: ind.inAccessories,
                    inAccessoriesCwu: ind.inAccessoriesCwu,
                    inVoltage: ind.voltage,
                    inBuildInTank: ind.buildInTank,
                    inTankCapacity: ind.tankCapacity,
                    inMonoSplit: ind.inMonoSplit,
                    inCooling: ind.cooling,
                    inActive: ind.active,
                })
            });
        } 

    })

    productList = filterAll(productList, filters);

    return productList;
}

function filterAll(products: Product[], filters: Filters) {
    // const filteredAmounts = [];
    let filteredProducts = products;
    _.forIn(filters, (value, filter) => {
        // const noBeforeFilter = Object.keys(filteredProducts).length;
        switch (filter) {
            case "type":
                filteredProducts = filterType(filteredProducts, value as string);
                break;
            case "series":
                filteredProducts = filterSeries(filteredProducts, value as string[]);
                break;
            case "voltage":
                filteredProducts = filterVoltage(filteredProducts, value as number);
                break;
            case "powerOutput":
                switch (filters.mode) {
                    case 'monowalentny':
                        filteredProducts = filterPower(filteredProducts, value as number, filters.outsideTemp, filters.outputTemp, null);
                        break;
                    case 'monoenergetyczny':
                        filteredProducts = filterPower(filteredProducts, value as number, filters.outsideTemp, filters.outputTemp, 'monoenergetyczny');
                        break;
                    case 'biwalentnyRownolegly':
                        filteredProducts = filterPower(filteredProducts, value as number, filters.outsideTemp, filters.outputTemp, filters.seckondHeaterPower);
                        break;
                    case 'biwalentnyAlternatywny':
                        filteredProducts = filterPower(filteredProducts, filters.extrapolatedPowerOutput as number, filters.bivalentTemp, filters.outputTemp, null);
                        break;
                
                    default:
                        break;
                }
                break;
            case "buildInTank":
                filteredProducts = filterBuildInTank(filteredProducts, value as boolean);
                break;
            case "waterHeating":
                filteredProducts = filterWaterHeating(filteredProducts, value as boolean);
                break;
            case "tankCapacity":
                filteredProducts = filterTankCapacity(filteredProducts, value as number);
                break;
            case "heaterPower":
                filteredProducts = filterHeaterPower(filteredProducts, value as any);
                break;
            case "heaterVoltage":
                filteredProducts = filterHeaterVoltage(filteredProducts, value as number);
                break;
            default:
                break;
        }
        // const noAfterFilter = Object.keys(filteredProducts).length;
        // filteredAmounts.push({
        //     filter: filter,
        //     amount: 100 - (noAfterFilter / noBeforeFilter * 100)
        // })
    })
    return filteredProducts;
}

export function getProductByName(name: string, products: Product[]) {
    return products.find(product => product.name === name);
}

export function getPowerArrFromObj( pompPowerObj: {} ) {
    return Object.keys(pompPowerObj)
            .sort( (a, b) => Number(b) - Number(a) )
            .reverse()
            .map( (temp, i) => pompPowerObj[temp as keyof typeof pompPowerObj] );
}

export function getPompPowerArrsByTemp(product: Product, outputTemp: number | string) {
    const pompPowerObjMax = product.max[String(outputTemp) as keyof OutputTemps];
    let pompPowerMax: any[] = getPowerArrFromObj(pompPowerObjMax);
    pompPowerMax = pompPowerMax.map( power => Number(power));
    const pompPowerObjMin = product.min[String(outputTemp) as keyof OutputTemps];
    let pompPowerMin: any[] = getPowerArrFromObj(pompPowerObjMin);
    pompPowerMin = pompPowerMin.map( power => Number(power));
    return {
        max: pompPowerMax,
        min: pompPowerMin
    }
}

export function transoformPowerArrToPoints(arr: any[], labels: number[]) {
    return arr.map( (val, i) => {
        return {
            x: labels[i],
            y: val
        }
    }).filter(val => {
        if(val.y === null || val.y === undefined || val.y === "-" || isNaN(val.y) ) {
            return false;
        } else return true;
    })
}

export function findOutputTemp(selectedRecivers: {}, temps: any):any {
    const tempsArr:number[] = [];
    const reciverTemps = {
        ogrzewaniePodlogowe: temps[0],
        grzejnikiPlytowe: temps[1],
        klimakonwertery: temps[2],
        otherReciver: temps[3],
    }
    for (const reciver in selectedRecivers) {
        if (Object.prototype.hasOwnProperty.call(selectedRecivers, reciver)) {
            const selected = selectedRecivers[reciver as keyof typeof selectedRecivers];
            if(selected) {
                tempsArr.push( Number(reciverTemps[reciver as keyof typeof reciverTemps]) );
            }
        }
    }
    return roundUpOutputTemp( Math.max( ...tempsArr ) );
}


export function roundUpOutputTemp( temp: number ) {
    if(temp > 60) return 60;
    const temps = outputTemps;

    const outputTempIndex = _.sortedIndex(temps, temp);
    const outputTemp = temps[outputTempIndex];

    return outputTemp;
}

export function filterPower(products: Product[], thermalLoad: number | null, outsideTemp: number | null, outputTemp: number | null, modifier: number | string | null) {
    if(thermalLoad === null || outsideTemp === null || outputTemp === null) return products;

    let filteredProducts = products.filter( product => {
        let requiredPower = thermalLoad;
        if(modifier === 'monoenergetyczny') {
            if(!product.inHeater) return false;
            requiredPower = thermalLoad - product.inHeater;
        } else if (!isNaN(modifier as any)) {
            requiredPower = thermalLoad - Number(modifier);
        }

        const { max } = product;
        const outputTempsObj = max[String(outputTemp) as keyof typeof max];
        const tempsArr = _.keys(outputTempsObj).sort( (a, b) => Number(b) - Number(a) ).reverse().map(val => Number(val));
        
        if( tempsArr.includes( outsideTemp ) && Number(outputTempsObj[String(outsideTemp) as keyof typeof outputTempsObj]) >= requiredPower) {
            product.extrapolatedPower = Number(outputTempsObj[String(outsideTemp) as keyof typeof outputTempsObj]);
            return true;
        }

        const insertIndex = _.sortedIndex( tempsArr, outsideTemp );
        let lowerTemp = tempsArr[insertIndex-1];
        let higherTemp = tempsArr[insertIndex];

        const outputTemps = max[String(outputTemp) as keyof OutputTemps];

        if(isNaN(outputTemps[String(higherTemp)]) && outputTemps[String(tempsArr[insertIndex+1])] && !isNaN(outputTemps[String(tempsArr[insertIndex+1])])) {
            higherTemp = tempsArr[insertIndex+1];
        }
        if(isNaN(outputTemps[String(lowerTemp)]) && outputTemps[String(tempsArr[insertIndex-2])] && !isNaN(outputTemps[String(tempsArr[insertIndex-2])])) {
            lowerTemp = tempsArr[insertIndex-2];
        }


        if(isNaN( outputTemps[String(lowerTemp)] ) ) return false;
        else {
            const modifiers = getLinearEquationModifiers([
                Number(tempsArr[insertIndex-1]), 
                Number( max[String(outputTemp) as keyof OutputTemps][String(lowerTemp)] ),
                Number(tempsArr[insertIndex+1]), 
                Number( max[String(outputTemp) as keyof OutputTemps][String(higherTemp)] ),
            ]);
            const extrapolatedPower = calcValueForLinearEquation(modifiers, outsideTemp);
            product.extrapolatedPower = extrapolatedPower;
            if(extrapolatedPower >= requiredPower) return true;
            else return false;
        };
    })
    if(modifier === 'monoenergetyczny') {
        filteredProducts = sortInHeaterPower(filteredProducts);
    }
    filteredProducts.sort( (a, b) => {
        if(a.extrapolatedPower && b.extrapolatedPower) {
            return a.extrapolatedPower - b.extrapolatedPower;
        } else return 0;
    });
    return filteredProducts;
}

function sortInHeaterPower(products: Product[]) {
    const sortedProducts = products.sort( (a, b) => {
        if(a.inHeater && b.inHeater) {
            return b.inHeater - a.inHeater;
        } else return 0;
    });
    return sortedProducts;
}

export function getLinearEquationModifiers(points: number[]) {
    const x1 = points[0];
    const y1 = points[1];
    const x2 = points[2];
    const y2 = points[3];
    const a = (y2 -y1)/(x2 - x1);
    const b = y1 - a * x1;

    return [a, b]
}

export function findIntersectionPointFromPoints(lineOne: {x:number, y:number}[], lineTwo: [{x:number, y:number}, {x:number, y:number}]) {
    const modOne = getLinearEquationModifiers([lineOne[0].x, lineOne[0].y, lineOne[1].x, lineOne[1].y]);
    const modTwo = getLinearEquationModifiers([lineTwo[0].x, lineTwo[0].y, lineTwo[1].x, lineTwo[1].y]);

    const x = (modTwo[1] - modOne[1]) / (modOne[0] - modTwo[0]);
    const y = modOne[0] * x + modOne[1];
    return { x: x, y: y }
}

export function findIntersectionPointFromPower(buildingPoints: {x:number, y:number}[], powerPoints: {x:number, y:number}[]): { x: number, y: number } | undefined {
    if(buildingPoints.length !== 2) return;
    for(let i = 0; i < powerPoints.length; i++) {
        const point = powerPoints[i];
        const nextPoint = powerPoints[i+1];
        if(point && nextPoint && point.y && nextPoint.y) {
            const intersection = findIntersectionPointFromPoints(buildingPoints, [point, nextPoint]);
            if(intersection.x && intersection.y) { 
                if( (point.y < intersection.y && intersection.y < nextPoint.y ) || (point.y > intersection.y && intersection.y > nextPoint.y ) ) return intersection 
            };
        }
    }
    return;
}

export function calcValueForLinearEquation(modifiers: number[], input: number) {
    const output = modifiers[0] * input + modifiers[1];
    return output;
}

export function extrapolateValue(points: number[], input: number) {
    const modifiers = getLinearEquationModifiers(points);
    return calcValueForLinearEquation(modifiers, input);
}

export function filterType(products: Product[], type: string) {
    if(type === null || type === "") return products;
    const filteredProducts = products.filter( product => {
        return product.outMonoSplit === type;
    })
    return filteredProducts;
}

export function filterSeries(products: Product[], series: string[]) {
    if(series.length === 0) return products;
    const filteredProducts = products.filter( product => {
        return series.includes(product.outSeries);
    })
    return filteredProducts;
}

export function filterVoltage(products: Product[], voltage: number) {
    if(voltage === null) return products;
    const filteredProducts = products.filter( product => {
        return product.outVoltage === voltage;
    })
    return filteredProducts;
}

// Czy pompa ma podgrzewać wodę?
export function filterWaterHeating(products: Product[], waterHeating: boolean) {
    if(waterHeating === undefined || waterHeating === null || waterHeating === true) return products;
    const filteredProducts = products.filter( product => {
        return product.inBuildInTank === false;
    })
    return filteredProducts;
}

// Czy zasobnik wbudowany?
export function filterBuildInTank(products: Product[], buildInTank: boolean) {
    if(buildInTank === undefined || buildInTank === null) return products;
    const filteredProducts = products.filter( product => {
        return product.inBuildInTank === buildInTank;
    })
    return filteredProducts;
}

export function filterTankCapacity(products: Product[], tankCapacity: number) {
    if(tankCapacity === undefined || tankCapacity === null) return products;
    const filteredProducts = products.filter( product => {
        if(product.inTankCapacity) {
            let tankCapArr:any = product.inTankCapacity;

            if(typeof tankCapArr === 'string' || tankCapArr instanceof String) {
                tankCapArr = tankCapArr.split(',');
            }

            if( !isNaN(tankCapArr) ) {
                if(Number(tankCapArr) === Number(tankCapacity)) return true;
                else return false;
            }

            let found = false;
            tankCapArr.forEach( (tankCap: any) => {
                if(Number(tankCap) === Number(tankCapacity)) found = true;
            });
            return found;
        } else return false;
    })
    return filteredProducts;
}

export function filterHeaterPower(products: Product[], power: number[]) {
    if( power.length === 0) return products;
    const filteredProducts = products.filter( product => {
        return product.inHeater !== undefined && power.includes(product.inHeater);
    })
    return filteredProducts;
}

export function filterHeaterVoltage(products: Product[], voltage: number) {
    if(voltage === null) return products;
    const filteredProducts = products.filter( product => {
        return product.inVoltage === voltage;
    })
    return filteredProducts;
}
import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import objetosciowy from '../../../img/objetosciowy.png';
import warstwowy from '../../../img/warstwowy.png';
import clsx from 'clsx';

export default function InfoZasobniki() {
    return (
        <Container style={{
            fontSize: '1rem',
            overflow: 'auto',
        }}>
            <Row>
                <Col xs={12} md={6}>
                    <h2 className={ clsx('text-2xl', 'text-center')}>Objętościowy podgrzew ciepłej wody użytkowej</h2>
                    <img src={ objetosciowy } alt="Objętościowy podgrzew ciepłej wody użytkowej" style={{
                        width: '100%'
                    }} />
                    
                W jednostkach z wbudowanym zasobnikiem ciepłej wody użytkowej woda przygotowywana jest na bieżąco w sposób przepływowy. Podgrzewanie wody odbywa się za pomocą osobnego, wbudowanego wymiennika płytowego. Zimna woda przetłaczana jest przez niewielkich rozmiarów pompę obiegową z dolnej części zasobnika i po podgrzaniu wprowadzana jest do jego górnej części. W zasobniku warstwowym powstaje naturalne uwarstwienie warstw wody tzn. ciepła woda akumulowana jest w górnej części zasobnika, podczas gdy chłodniejsza woda pozostaje na jego dole. Uniemożliwia to mieszanie się i wychładzanie wody w całym zasobniku. Z górnej części zbiornika zawsze pobierana jest ciepła woda. Dzięki zastosowaniu takiej techniki podgrzewu można było również zmniejszyć kubaturę jednostki, co jest niemożliwe w przypadku konieczności wyposażenia urządzenia w wężownicę.
                </Col>
                <Col xs={12} md={6}>
                    <h2 className={ clsx('text-2xl', 'text-center')}>Warstwowy podgrzew ciepłej wody użytkowej</h2>
                    <img src={ warstwowy } alt="Warstwowy podgrzew ciepłej wody użytkowej" style={{
                        width: '100%'
                    }} />
                    W tego rodzaju zasobnikach ciepłej wody, zimna woda podgrzewana jest za pomocą umieszczonej wewnątrz niego rury, w kształcie spirali czyli wężownicy. Wężownica zasilana jest wodą grzewczą wyprodukowaną przez pompę ciepła. Podczas poboru ciepłej wody użytkowej, do zasobnika wpływa zimna woda wodociągowa, która miesza się z wodą znajdującą się w zasobniku. Powoduje to obniżenie średniej temperatury zasobnika. Pobór dużej ilości ciepłej wody oznacza konieczność oczekiwania na ponowny, objętościowy, podgrzew.
                </Col>
            </Row>
        </Container>
        
    )
}
import React from "react";
import technologyDescriptions from "../../constants/technologyDescriptions.json";
import "./technologyDescr.css";
import ecoImg from "../../../img/ecoLogo.png";
import powerImg from "../../../img/powerLogo.png";
import zubadanImg from "../../../img/zubadanLogo.png";
import r290Img from "../../../img/r290Logo.png";

export default function TechnologyDescr(props: { tech: "zubadan" | "eco" | "power" | "r290" | string }) {
  const { tech } = props;

  const desc = technologyDescriptions[tech as "zubadan" | "eco" | "power" | "r290"];
  if (!desc) return <></>;

  function getTechString() {
    switch (tech) {
      case "zubadan":
        return "Zubadan Inverter";
      case "power":
        return "Power Inverter";
      case "eco":
        return "Eco Inverter";
      case "r290":
        return "R290 Inverter";
      default:
        break;
    }
  }

  const logos = {
    eco: ecoImg,
    power: powerImg,
    zubadan: zubadanImg,
    r290: r290Img,
  };

  return (
    <div className="technologyDesc-container" key={"techDesc" + new Date().getUTCMilliseconds()}>
      <div className="technologyDesc-header-container">
        <img src={logos[tech as "zubadan" | "eco" | "power" | "r290"]} alt="Logo serii" />
        <h2>{getTechString()}</h2>
      </div>
      <h3 className="technologyDesc-h3">Najważniejsze cechy</h3>
      <ul className="technologyDesc-ul">
        {desc.feats.map((feat) => (
          <li className="technologyDesc-li" key={feat} dangerouslySetInnerHTML={{ __html: feat }}></li>
        ))}
      </ul>
      <p className="technologyDesc-p">{desc.desc}</p>
    </div>
  );
}

import React from 'react';
import { Page, Text, View, Document, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { SzacowanieMocy } from '../../../redux/actionTypes/szacowanieMocyTypes';
import jZew from '../../../img/j-zew.png';
import puhzshw230yka from '../../../img/puhz-shwm230yka.jpg';
import jZewEco from '../../../img/ecoInverter.jpg';
import jZewR290 from '../../../img/R290Inverter.jpg';
import boiler from '../../../img/boiler.png';
import jWewCylinder from '../../../img/j-wewCylinder.png';
import jWewHydrobox from '../../../img/j-wewHydrobox.png';
import zawor from '../../../img/zawor.png';
import mur from '../../../img/mur.png';
import umywalka from '../../../img/umywalka.png';
import _ from 'lodash';


Font.register({ family: 'MontserratBold', src: 'https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_dJE7g4.ttf' });
Font.register({ family: 'MontserratRegular', src: 'https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_ZpC7g4.ttf' });
Font.register({ family: 'MontserratLight', src: 'https://fonts.gstatic.com/s/montserrat/v15/JTURjIg1_i6t8kCHKm45_aZA7g4.ttf' });

// Create styles
const styles = StyleSheet.create({
    page: {
        // flexDirection: 'row',
        fontFamily: 'MontserratRegular'
    },
    section: {
        margin: 10,
        padding: 10,

    },
    topStripe: {
        margin: 0,
        padding: 0,
        width: '210mm',
        height: '4mm',
        borderTopColor: '#c6c6c6',
        borderTopStyle: 'solid',
        borderTopWidth: '10mm',
    },
    heading: {
        color: '#FF0000',
        textTransform: 'uppercase',
        fontSize: '40',
        fontWeight: 'bold',
        marginTop: '20pt',
        fontFamily: 'MontserratBold'
    },
    subheading: {
        color: '#808284',
        textTransform: 'uppercase',
        fontSize: '20',
        fontWeight: 'light',
        marginTop: '25pt',
        fontFamily: 'MontserratLight'
    },
    valPair: {
        display: 'flex',
        flexDirection: 'row',
    },
    variable: {
        backgroundColor: '#ededed',
        width: '50%',
        fontSize: 10,
        padding: '5pt',
        paddingLeft: '10pt',
        margin: '1mm',
    },
    val: {
        border: '1pt solid #ededed',
        width: '50%',
        fontSize: 10,
        padding: '5pt',
        paddingLeft: '10pt',
        margin: '1mm',
    },
    variableImportant: {
        backgroundColor: '#000',
        color: '#fff',
        width: '50%',
        fontSize: 10,
        padding: '5pt',
        paddingLeft: '10pt',
        margin: '1mm',
    },
    valImportant: {
        border: '1pt solid #000',
        color: '#000',
        width: '50%',
        fontSize: 10,
        padding: '5pt',
        paddingLeft: '10pt',
        margin: '1mm',
    },
    chartImg: {
        width: '200mm',
    },
    schematContainer: {
        width: '185mm',
        height: '120mm',
        borderRadius: '3mm',
        border: '2pt solid #ededed'
    },
    schematImg: {
        position: 'absolute',
    },
    energyClassContainer: {
        width: '185mm',
        backgroundColor: '#ededed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '3mm',
        borderRadius: '3mm',
        marginTop: '5mm'
    }

});

interface OwnProps {
    strefaKlimatyczna: any,
    szacowanieMocy: SzacowanieMocy,
    daneInstalacji: any,
    podgrzewanieWody: any,
    wynik: any,
}

function makeReciversList(daneInstalacji: any) {
    const reciversArr: any[] = [];

    _.forEach(daneInstalacji.selectedRecivers, (val, key) => {
        if (val) {
            if (key === 'ogrzewaniePodlogowe') reciversArr.push('Ogrzewanie podłogowe');
            if (key === 'grzejnikiPlytowe') reciversArr.push('Grzejniki płytowe');
            if (key === 'klimakonwertery') reciversArr.push('Klimakonwertery');
            if (key === 'otherReciver') reciversArr.push(daneInstalacji.otherReciverName);
        } else return undefined;
    });
    return reciversArr.join(', ');
}

// Create Document Component
export default function MyDocument(props: OwnProps) {
    const { strefaKlimatyczna, szacowanieMocy, daneInstalacji, podgrzewanieWody, wynik } = props;

    const placeholderToReplace = {
        "{{iloscStrefGrzewczych}}": makeReciversList(daneInstalacji).split(', ').length.toString(),
    }

    function replacePlaceholders(text: string) {
        _.forEach(placeholderToReplace, (val, key) => {
            const regex = new RegExp(key, 'g');
            text = text.replace(regex, val);
        });
        return text;
    }

    function getModeString() {
        switch (wynik.filters.mode) {
            case 'monowalentny':
                return 'Tylko pompa ciepła';
            case 'monoenergetyczny':
                return 'Pompa ciepła + grzałka elektryczna';
            case 'biwalentnyRownolegly':
                return 'Pompa ciepła + drugie źródło ciepła';
            case 'biwalentnyAlternatywny':
                return 'Pompa ciepła lub drugie źródło ciepła';
            default:
                break;
        }
    }

    function getSeriesString() {
        switch (wynik.selectedProduct.outSeries) {
            case 'zubadan':
                return 'Zubadan Inverter';
            case 'power':
                return 'Power Inverter';
            case 'eco':
                return 'Eco Inverter';
            default:
                break;
        }
    }

    function round(val: any) {
        return Math.round(Number(val) * 100) / 100;
    }

    function getAccessories() {
        if (wynik.selectedProduct.inAccessories) {
            const accessoriesArr = wynik.selectedProduct.inAccessories.split(';');
            return accessoriesArr.map((accessorie: string) => {
                return <View><Text>{replacePlaceholders(accessorie)}{"\n"}</Text></View>
            })
        } else {
            return '';
        }
    }

    function getAccessoriesCwu() {
        if (wynik.selectedProduct.inAccessoriesCwu) {
            const accessoriesArr = wynik.selectedProduct.inAccessoriesCwu.split(';');
            return accessoriesArr.map((accessorie: string) => {
                return <View><Text>{replacePlaceholders(accessorie)}{"\n"}</Text></View>
            })
        } else {
            return '';
        }
    }

    function getAccessoriesOut() {
        if (wynik.selectedProduct.outAccessories) {
            const accessoriesArr = wynik.selectedProduct.outAccessories.split(';');
            return accessoriesArr.map((accessorie: string) => {
                return <View><Text>{replacePlaceholders(accessorie)}{"\n"}</Text></View>
            })
        } else {
            return '';
        }
    }

    function getAccessoriesCwuOut() {
        if (wynik.selectedProduct.outAccessoriesCwu) {
            const accessoriesArr = wynik.selectedProduct.outAccessoriesCwu.split(';');
            return accessoriesArr.map((accessorie: string) => {
                return <View><Text>{replacePlaceholders(accessorie)}{"\n"}</Text></View>
            })
        } else {
            return '';
        }
    }

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.topStripe}></View>
                <View style={styles.section}>
                    <Text style={styles.heading}>Dane doboru</Text>
                    <Text style={styles.subheading}>Strefa klimatyczna</Text>

                    <View style={styles.valPair}>
                        <Text style={styles.variable}>Lokalizacja:</Text>
                        <Text style={styles.val}>{strefaKlimatyczna.address.address && strefaKlimatyczna.address.address.state}</Text>
                    </View>

                    <View style={styles.valPair}>
                        <Text style={styles.variable}>Strefa klimatyczna:</Text>
                        <Text style={styles.val}>{strefaKlimatyczna.climateZone}</Text>
                    </View>

                    <View style={styles.valPair}>
                        <Text style={styles.variableImportant}>Min. zakładana temp. zewnętrzna:</Text>
                        <Text style={styles.valImportant}>{strefaKlimatyczna.temp} °C</Text>
                    </View>


                    <Text style={styles.subheading}>Szacowanie Mocy</Text>

                    <View style={styles.valPair}>
                        <Text style={styles.variable}>Temperatura utrzymywana w budynku:</Text>
                        <Text style={styles.val}>{szacowanieMocy.tempInBuilding} °C</Text>
                    </View>

                    {szacowanieMocy.insulationPerformance ?
                        <View style={styles.valPair}>
                            <Text style={styles.variable}>Wydajność izolacji:</Text>
                            <Text style={styles.val}>{szacowanieMocy.insulationPerformance} W/(m&sup3;K)</Text>
                        </View>
                        : <View></View>}

                    {szacowanieMocy.primodalEnergy ?
                        <View style={styles.valPair}>
                            <Text style={styles.variable}>Energia pierwotna:</Text>
                            <Text style={styles.val}>{szacowanieMocy.primodalEnergy} kWh/m&sup2;rok</Text>
                        </View>
                        : <View></View>}

                    {szacowanieMocy.buildingCubature && szacowanieMocy.selectionMethod && szacowanieMocy.selectionMethod === 'standardBudynku' && szacowanieMocy.buildingStandard && szacowanieMocy.buildingStandard.units === "insulationPerformance" ?
                        <View style={styles.valPair}>
                            <Text style={styles.variable}>Kubatura budynku:</Text>
                            <Text style={styles.val}>{szacowanieMocy.buildingCubature} m&sup3;</Text>
                        </View>
                        : <View></View>}

                    {szacowanieMocy.buildingArea && szacowanieMocy.selectionMethod && ((szacowanieMocy.selectionMethod === 'standardBudynku' && szacowanieMocy.buildingStandard && szacowanieMocy.buildingStandard.units === "primodalEnergy") || szacowanieMocy.selectionMethod === 'charakterystyka') ?
                        <View style={styles.valPair}>
                            <Text style={styles.variable}>Powierzchnia budynku:</Text>
                            <Text style={styles.val}>{szacowanieMocy.buildingArea} m&sup2;</Text>
                        </View>
                        : <View></View>}

                    <View style={styles.valPair}>
                        <Text style={styles.variable}>Temperatura graniczna ogrzewania:</Text>
                        <Text style={styles.val}>{szacowanieMocy.heatingLimitTemp} °C</Text>
                    </View>

                    <View style={styles.valPair}>
                        <Text style={styles.variableImportant}>Obciążenie cieplne budynku:</Text>
                        <Text style={styles.valImportant}>{szacowanieMocy.buildingThermalLoad} kW</Text>
                    </View>


                    <Text style={styles.subheading}>Dane instalacji</Text>

                    <View style={styles.valPair}>
                        <Text style={styles.variable}>Wybrane odbiorniki cieplne:</Text>
                        <Text style={styles.val}>{makeReciversList(daneInstalacji)}</Text>
                    </View>

                    <View style={styles.valPair}>
                        <Text style={styles.variableImportant}>Obliczona temperatura wyjściowa:</Text>
                        <Text style={styles.valImportant}>{wynik.filters.outputTemp} °C</Text>
                    </View>


                    <Text style={styles.subheading}>Podgrzewanie wody</Text>

                    <View style={styles.valPair}>
                        <Text style={styles.variable}>Podgrzewanie wody:</Text>
                        <Text style={styles.val}>{podgrzewanieWody.waterHeating ? 'TAK' : 'NIE'}</Text>
                    </View>

                    {podgrzewanieWody.waterHeating && podgrzewanieWody.waterUsage !== 0 ? <View>
                        <View style={styles.valPair}>
                            <Text style={styles.variable}>Liczba mieszkańców:</Text>
                            <Text style={styles.val}>{podgrzewanieWody.habitantsNo}</Text>
                        </View>

                        <View style={styles.valPair}>
                            <Text style={styles.variable}>Średnie zużycie wody na mieszkańca:</Text>
                            <Text style={styles.val}>{podgrzewanieWody.waterUsage} l</Text>
                        </View>
                    </View> : <View></View>}

                    {wynik.filters.buildInTank !== null ?
                        <View style={styles.valPair}>
                            <Text style={styles.variableImportant}>Zbiornik wbudowany w jednostkę wew.:</Text>
                            <Text style={styles.valImportant}>{wynik.filters.buildInTank ? 'TAK' : 'NIE'}</Text>
                        </View>
                        : <View></View>}


                    {podgrzewanieWody.waterHeating ?
                        <View style={styles.valPair}>
                            <Text style={styles.variableImportant}>Pojemność zbiornika:</Text>
                            <Text style={styles.valImportant}>{wynik.filters.tankCapacity} l</Text>
                        </View>
                        : <View></View>}

                </View>
            </Page>


            <Page size="A4" style={styles.page}>
                <View style={styles.topStripe}></View>
                <View style={styles.section}>

                    {wynik.canvasImg ?
                        <View>
                            <Image 
                                style={styles.chartImg} 
                                src={wynik.canvasImg} 
                            />
                        </View>
                        : <View></View>}

                    <View style={styles.valPair}>
                        <Text style={styles.variableImportant}>Wybrany produkt:</Text>
                        <Text style={styles.valImportant}>{wynik.selectedProductName}</Text>
                    </View>

                    <View style={styles.valPair}>
                        <Text style={styles.variable}>Tryb pracy urządzenia:</Text>
                        <Text style={styles.val}>{getModeString()}</Text>
                    </View>

                    <View style={styles.valPair}>
                        <Text style={styles.variable}>Seria:</Text>
                        <Text style={styles.val}>{getSeriesString()}</Text>
                    </View>

                    <View style={styles.valPair}>
                        <Text style={styles.variable}>Napięcie:</Text>
                        <Text style={styles.val}>{wynik.selectedProduct.outVoltage} V</Text>
                    </View>

                    <View style={styles.valPair}>
                        <Text style={styles.variableImportant}>Moc grzewcza przy zakładanej min. temp. zewnętrznej:</Text>
                        <Text style={styles.valImportant}>{round(wynik.selectedProduct.extrapolatedPower)} kW</Text>
                    </View>

                    <View style={styles.valPair}>
                        <Text style={styles.variable}>Grzałka elektryczna:</Text>
                        <Text style={styles.val}>{wynik.selectedProduct.inHeater ? 'TAK' : 'NIE'}</Text>
                    </View>

                    {wynik.selectedProduct.inHeater ?
                        <View style={styles.valPair}>
                            <Text style={styles.variable}>Moc grzałki:</Text>
                            <Text style={styles.val}>{wynik.selectedProduct.inHeater} kW</Text>
                        </View>
                        : <View></View>}

                    <View style={styles.valPair}>
                        <Text style={styles.variable}>Temperatura biwalentna:</Text>
                        <Text style={styles.val}>{round(wynik.filters.bivalentTemp)} °C</Text>
                    </View>

                    { }
                    <View style={styles.valPair}>
                        <Text style={styles.variable}>Opis produktu: {}</Text>
                        <Text style={styles.val}>{wynik.selectedProduct.inDesc}</Text>
                    </View>
                    
                    <View style={styles.valPair}>
                        <Text style={styles.variable}>Opis produktu: </Text>
                        <Text style={styles.val}>{wynik.selectedProduct.outDesc}</Text>
                    </View>

                    <View style={styles.valPair}>
                        <Text style={styles.variable}>Akcesoria:</Text>
                        <Text style={{ ...styles.val, fontSize: '8pt' }}>
                            { /*
                            Czujnik temperatury obiegu grzewczego PAC-TH011-E {makeReciversList(daneInstalacji).includes(', ') ? '2 szt.' : '1 szt.'}{"\n"}
                            Bezprzewodowy termostat pomieszczeniowy (nadajnik) PAR-WT50R-E {makeReciversList(daneInstalacji).includes(', ') ? '2 szt.' : '1 szt.'}{"\n"}
                            Bezprzewodowy termostat pomieszczeniowy (odbiornik) PAR-WR51R-E {makeReciversList(daneInstalacji).includes(', ') ? '1 szt.' : '1 szt.'}{"\n"}
                            */}

                            { /* !wynik.selectedProduct.inBuildInTank && wynik.filters.tankCapacity && wynik.filters.waterHeating ? <View><Text>Zbiornik ciepłej wody użytkowej (CWU) WPS{wynik.filters.tankCapacity} 1 szt.{"\n"}</Text></View> : <View></View> */}

                            { /* eslint-disable-next-line eqeqeq */}
                            { /* podgrzewanieWody.waterHeating && !wynik.selectedProduct.inBuildInTank && wynik.selectedProduct.inTankCapacity != '0' ? <View><Text>Czujnik temperatury ciepłej wody użytkowej PAC-TH011TK2-E 1szt.{"\n"}</Text></View> : <View></View> */}

                            { getAccessories() }
                            {wynik.filters.waterHeating ? getAccessoriesCwu() : <View></View>}

                            { getAccessoriesOut() }
                            { podgrzewanieWody.waterHeating && wynik.selectedProduct.inBuildInTank === false ? getAccessoriesCwuOut() : <View></View> }
                        </Text>
                    </View>
                </View>
            </Page>



            <Page size="A4" style={styles.page}>
                <View style={styles.topStripe}></View>

                <View style={styles.section}>
                    <Text style={styles.subheading}>Schemat</Text>

                    <View style={styles.schematContainer}>

                        <View style={{
                            width: '80mm',
                            position: 'absolute',
                            top: '84mm',
                            left: '20mm',
                            borderTopColor: '#000',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1mm',
                        }}></View>
                        <View style={{
                            width: '50mm',
                            position: 'absolute',
                            top: '84mm',
                            left: '100mm',
                            borderTopColor: '#ff0000',
                            borderTopStyle: 'solid',
                            borderTopWidth: '1mm',
                        }}></View>



                        { wynik.selectedProduct.outName.includes( 'PUHZ-SHW230YKA' ) ?
                            <Image style={{
                                ...styles.schematImg,
                                width: '36mm',
                                top: '48mm',
                                left: '7mm',
                            }} src={puhzshw230yka} />
                            : wynik.selectedProduct.outSeries === 'eco' ?
                                <Image style={{
                                    ...styles.schematImg,
                                    width: '36mm',
                                    top: '60mm',
                                    left: '7mm',
                                }} src={jZewEco} />
                                :
                                wynik.selectedProduct.outSeries === 'r290' ?
                                    <Image style={{
                                        ...styles.schematImg,
                                        width: '36mm',
                                        top: '60mm',
                                        left: '7mm',
                                    }} src={jZewR290} />
                                    :
                                    <Image style={{
                                    ...styles.schematImg,
                                    width: '36mm',
                                    top: '62mm',
                                    left: '7mm',
                                }} src={jZew} />
                        }

                        <Image style={{
                            ...styles.schematImg,
                            width: '60mm',
                            top: '51mm',
                            left: '30mm',
                        }} src={mur} />

{ /* 
    Podgrzewanie wody: tak
    Zasobnik wbudowany: tak
*/ }
                        { podgrzewanieWody.waterHeating && wynik.selectedProduct.inBuildInTank ?
                            <View>
                                <View style={{
                                    width: '50mm',
                                    position: 'absolute',
                                    top: '52mm',
                                    left: '100mm',
                                    borderTopColor: '#ffba00',
                                    borderTopStyle: 'solid',
                                    borderTopWidth: '1mm',
                                }}></View>
                                <Text style={{
                                    position: 'absolute',
                                    top: '30mm',
                                    left: '124mm',
                                    fontSize: '11pt',
                                }}>Ciepła woda {"\n"}użytkowa</Text>
                                <Image style={{
                                    ...styles.schematImg,
                                    width: '43mm',
                                    top: '40mm',
                                    left: '124mm',
                                }} src={umywalka} />
                            </View>
                            : <View></View> }

{ /* 
    Podgrzewanie wody: tak
    Zasobnik wbudowany: nie
*/ }
                        { podgrzewanieWody.waterHeating && wynik.selectedProduct.inBuildInTank === false ?
                            <View>
                                <View style={{
                                    width: '50mm',
                                    position: 'absolute',
                                    top: '52mm',
                                    left: '130mm',
                                    borderTopColor: '#ffba00',
                                    borderTopStyle: 'solid',
                                    borderTopWidth: '1mm',
                                }}></View>
                                <Text style={{
                                    position: 'absolute',
                                    top: '30mm',
                                    left: '151mm',
                                    fontSize: '11pt',
                                }}>Ciepła woda {"\n"}użytkowa</Text>
                                <Image style={{
                                    ...styles.schematImg,
                                    width: '43mm',
                                    top: '40mm',
                                    left: '144mm',
                                }} src={umywalka} />
                                <View style={{
                                    width: '5mm',
                                    position: 'absolute',
                                    top: '52mm',
                                    left: '120mm',
                                    borderTopColor: '#ff0000',
                                    borderTopStyle: 'solid',
                                    borderTopWidth: '1mm',
                                }}></View>
                                <View style={{
                                    width: '50mm',
                                    height: '33mm',
                                    position: 'absolute',
                                    top: '52mm',
                                    left: '120mm',
                                    borderLeftColor: '#ff0000',
                                    borderLeftStyle: 'solid',
                                    borderLeftWidth: '1mm',
                                }}></View>
                                <Image style={{
                                    ...styles.schematImg,
                                    width: '20mm',
                                    top: '25mm',
                                    left: '124mm',
                                }} src={boiler} />
                                <Image style={{
                                    ...styles.schematImg,
                                    width: '7mm',
                                    height: '7mm',
                                    top: '80mm',
                                    left: '117mm',
                                }} src={zawor} />
                                <Text style={{
                                    position: 'absolute',
                                    top: '30mm',
                                    left: '86mm',
                                    fontSize: '11pt',
                                }}>Zasobnik ciepłej {"\n"}wody użytkowej</Text>
                            </View>
                        : <View></View> }



                        {wynik.selectedProduct.outMonoSplit !== 'mono' ?
                            <View>
                                {wynik.selectedProduct.inCylinderHydrobox === 'cylinder' ?
                                    <Image style={{
                                        ...styles.schematImg,
                                        width: '30mm',
                                        top: '30mm',
                                        left: '82mm',
                                    }} src={jWewCylinder} />
                                    :
                                    <Image style={{
                                        ...styles.schematImg,
                                        width: '30mm',
                                        top: '50mm',
                                        left: '82mm',
                                    }} src={jWewHydrobox} />
                                }
                                <Text style={{
                                    position: 'absolute',
                                    top: '104mm',
                                    left: '84mm',
                                    fontSize: '11pt',
                                }}>{wynik.selectedProduct.inName}</Text>
                            </View>
                        : <View></View>}
                        

                        <Text style={{
                            position: 'absolute',
                            top: '87mm',
                            left: '120mm',
                            fontSize: '11pt',
                        }}>Ogrzewanie {"\n"}pomieszczeń</Text>

                        <Text style={{
                            position: 'absolute',
                            top: '101mm',
                            left: '8mm',
                            fontSize: '11pt'
                        }}>{wynik.selectedProduct.outName}</Text>

                    </View>

                    <View style={styles.energyClassContainer}>


                        <Image style={{
                            width: '52mm',
                            borderRadius: '3mm',
                        }} src={process.env.REACT_APP_API_PUBLIC_ADDRESS + 'energyLabels/' + encodeURI(wynik.selectedProduct.name) + '.png'} />
                    </View>

                </View>
            </Page>

        </Document>
    );
}


import React, { useEffect } from 'react';
import { makeStyles, Theme, createStyles, useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';

import { Container, Row, Col } from 'react-bootstrap';

import Start from './steps/Start';
import StrefaKlimatyczna from './steps/strefaKlimatyczna/StrefaKlimatyczna';
import SzacowanieMocy from './steps/szacowanieMocy/SzacowanieMocy';
// import TrybPracy from './steps/TrybPracy';
import DaneInstalacji from './steps/daneInstalacji/DaneInstalacji';
import PodgrzewanieWody from './steps/podgrzewanieWody/PodgrzewanieWody';
import Wynik from './steps/wynik/Wynik';
import Raport from './steps/raport/raport';
import logo from '../img/logo.svg';
import clsx from 'clsx';
import './stepperMenu.css';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ecodan from '../img/ecodan.svg';
import produktySmall from '../img/produkty-small.png';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Link,
  } from "react-router-dom";
import store from '../redux/store';
import { getProducts, getSelection } from '../redux/actions/wynikActions';
import { useSelector } from 'react-redux';
import _ from 'lodash';


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
		},
		button: {
			marginRight: theme.spacing(1),
		},
		completed: {
			display: 'inline-block',
		},
		instructions: {
			marginTop: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
		appBar: {
			top: 'auto',
			bottom: 0,
		},
		topBar: {
			display: 'flex',
			flexDirection: 'row',
			marginBottom: '2rem',
			marginTop: '2rem'
		},
		stepper: {
			width: '100%'
		},
		logoContainer: {
			minWidth: '150px',
			background: '#fff',
			display: 'flex',
			alignContent: 'center',
			justifyContent: 'center'
		},
		logo: {
			width: '120px',

		}
	}),
);

function getSteps() {
	return [
		'Start',
		'Strefa klimatyczna',
		'Szacowanie mocy',
		'Dane instalacji',
		'Ciepła woda użytkowa',
		'Wynik',
		'Raport'
	];
}


function getStepRoute(step: number) {
	switch (step) {
		case 0:
			return "";
		case 1:
			return "strefa-klimatyczna";
		case 2:
			return "szacowanie-mocy";
		case 3:
			return "dane-instalacji";
		case 4:
			return "podgrzewanie-wody";
		case 5:
			return "wynik";
		case 6:
			return "raport";
		default:
			return 'Unknown step';
	}
}

export default function StepperMenu() {
	const classes = useStyles();

	// Get products from server
	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const id = urlParams.get('id');
		if(id) {
			store.dispatch( getSelection(id) );
		}
		store.dispatch( getProducts() );
	}, []);

	const [activeStep, setActiveStep] = React.useState(0);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [stepResult, setStepResult] = React.useState('');
	const [completed, setCompleted] = React.useState<{ [k: number]: boolean }>({});
	const steps = getSteps();

	const theme = useTheme();
	const mobileView = useMediaQuery(theme.breakpoints.down('md'));

	const totalSteps = () => {
		return steps.length;
	};

	const completedSteps = () => {
		return Object.keys(completed).length;
	};

	const isLastStep = () => {
		return activeStep === totalSteps() - 1;
	};

	const allStepsCompleted = () => {
		return completedSteps() === totalSteps();
	};

	const handleNext = function() {
		const newActiveStep =
			isLastStep() && !allStepsCompleted()
				? 
				steps.findIndex((step, i) => !(i in completed))
				: activeStep + 1;
		setActiveStep(newActiveStep);
	};

	const handleBack = function() {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleStep = (step: number) => () => {
		setActiveStep(step);
	};


    const filters = useSelector( (state:any) => state.wynikReducer.filters );
    const selectedProduct = useSelector( (state:any) => state.wynikReducer.selectedProductName );
    const selectedRecivers = useSelector( (state:any) => state.daneInstalacjiReducer.selectedRecivers);
    const waterHeating = useSelector( (state:any) => state.podgrzewanieWodyReducer.waterHeating);

	useEffect(() => {
		if(activeStep !== 0) completed[0] = true;
		else completed[0] = false;

		if(filters.outsideTemp && filters.outsideTemp !== 0) completed[1] = true;
		else completed[1] = false;

		if(filters.powerOutput && filters.powerOutput !== 0) completed[2] = true;
		else completed[2] = false;

		if( _.includes(selectedRecivers, true) ) completed[3] = true;
		else completed[3] = false;
		
		if(waterHeating === false || filters.buildInTank === false || (filters.tankCapacity && filters.tankCapacity !== 0) ) completed[4] = true;
		else completed[4] = false;
		
		if( selectedProduct ) completed[5] = true;
		else completed[5] = false;
		
		setCompleted({...completed});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filters, activeStep, selectedRecivers, selectedProduct])

	const handleReset = () => {
		setActiveStep(0);
		setCompleted({});
	};

	function ActiveStepName() {
		const steps = getSteps();
		return <p style={{ marginBottom: 0 }}>{ steps[activeStep] }</p>;
	}

	return (
		<div className={classes.root}>
			<Router>
			<AppBar position="sticky" className={ clsx(classes.topBar, 'container' )} style={{ top: '15px' }}>
				<div className={classes.logoContainer}> <img src={logo} alt="logo" className={classes.logo} /> </div>
				
				{ !mobileView ? 
					<Stepper nonLinear activeStep={activeStep} className={classes.stepper}>
						{steps.map((label, index) => (
							<Step key={label}>
								<StepButton onClick={handleStep(index)} completed={completed[index]} style={{ margin: '0px -1px', padding: '0px 1px' }}>
									<Link to={ getStepRoute(index) } style={{ display: 'block', margin: '-29px -36px', padding: '29px 36px', color: 'inherit' }}>
										{label}
									</Link>
								</StepButton>
							</Step>
						))}
					</Stepper>
				:
					<MobileStepper
						steps={steps.length}
						variant="text"
						activeStep={activeStep}
						className={classes.stepper}
						nextButton={ activeStep !== 6 ?
							<Link to={ getStepRoute(activeStep+1) }>
								<Button
									variant="contained"
									color="primary"
									onClick={handleNext}
									className={classes.button}
								>
									Dalej 
								</Button>
							</Link>
							: ''
						  }
						backButton={ activeStep !== 0 ?
							<Link to={ getStepRoute(activeStep-1) }>
								<div style={{ display: 'flex', width: '53%', marginRight: '-20%', alignItems: 'center', justifyContent: 'space-between' }}>
									<Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
										Wstecz
									</Button>
									<ActiveStepName />
								</div>
							</Link>
							: ''
						  }
					/>

				}
			</AppBar>

			<div>
				{allStepsCompleted() ? (
					<div>
						<Typography className={classes.instructions}>
							All steps completed - you&apos;re finished
                    	</Typography>
						<Button onClick={handleReset}>Reset</Button>
					</div>
				) : (
						<div>
							<div>        
								<Switch>
									<Route exact path="/"><Start /></Route>;
									<Route path="/strefa-klimatyczna"><StrefaKlimatyczna setActiveStep={ setActiveStep } /></Route>;
									<Route path="/szacowanie-mocy"><SzacowanieMocy setActiveStep={ setActiveStep } /></Route>;
									<Route path="/dane-instalacji"><DaneInstalacji setActiveStep={ setActiveStep } /></Route>;
									<Route path="/podgrzewanie-wody"><PodgrzewanieWody setActiveStep={ setActiveStep } /></Route>;
									<Route path="/wynik"><Wynik setActiveStep={ setActiveStep } /></Route>;
									<Route path="/raport"><Raport setActiveStep={ setActiveStep } completedSteps={ completed } /></Route>;
								</Switch>
							</div>
							{ !([0, 5].includes(activeStep)) ? 
								<div style={{
									display: 'flex',
									justifyContent: 'flex-end',
									marginBottom: '6rem',
									marginRight: '3rem'
								}}>
									<img src={produktySmall} alt="prodkuty Ecodan" style={{ maxWidth: '300px'}} />
								</div>
							: '' }
							{ !mobileView ? 
								<AppBar position="fixed" color="inherit" className={ clsx(classes.appBar, 'wstecz-dalej-root') } >
									<Container>
										<Row style={{ display: 'flex', justifyContent: 'space-between', margin: '1rem' }}>
											<Col style={{ 
												display: 'flex',
												alignItems: 'center'
											}}>
												{ activeStep !== 0 ?
												<Link to={ getStepRoute(activeStep-1) }>
													<Button
														color={ "tertiary" as any }
														variant="contained"
														disabled={activeStep === 0} 
														onClick={handleBack} 
														className={classes.button}
													>
														Wstecz
													</Button>
												</Link>
												: ''}
												{ !([0, 6].includes(activeStep)) ?
												<Link
														to={ getStepRoute(activeStep+1) 
													}>
													<Button
														color="secondary" 
														variant="contained"
														onClick={handleNext}
														disabled={activeStep === 6} 
														className={classes.button}
													>
														Dalej
													</Button>
												</Link>
												: ''}
											</Col>
											<Col style={{
												display: 'flex',
												justifyContent: 'flex-end'
											}}>								
												<img style={{ width: '150px'}} src={ ecodan } alt="Logo Ecodan" className="img-logo" />
											</Col>
										</Row>

									</Container>
								</AppBar>
							: '' }


						</div>
					)}
			</div>
			</Router>
		</div>
	);
}

import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

export default function TabelaPodpowiedziMocy() {
    return (
        <TableContainer >
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ fontWeight: 'bold'}}>Standard wykonania budynku</TableCell>
                        <TableCell style={{ fontWeight: 'bold'}}>Wskaźnik Ep</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Starszy budynek jednorodzinny, bez ocieplenia</TableCell>
                        <TableCell>170-200 kWh/m<sup>2</sup>rok</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Starszy budynek jednorodzinny, ocieplony</TableCell>
                        <TableCell>130-160 kWh/m<sup>2</sup>rok</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Budynek jednorodzinny wg. warunków WT2014</TableCell>
                        <TableCell>Max. 120	kWh/m<sup>2</sup>rok</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Budynek jednorodzinny wg. warunków WT2017</TableCell>
                        <TableCell>Max. 95 kWh/m<sup>2</sup>rok</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Budynek jednorodzinny wg. warunków WT2021</TableCell>
                        <TableCell>Max. 70 kWh/m<sup>2</sup>rok</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Dom energooszczędny</TableCell>
                        <TableCell>50-80 kWh/m<sup>2</sup>rok</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Dom pasywny</TableCell>
                        <TableCell>10-15 kWh/m<sup>2</sup>rok</TableCell>
                    </TableRow>

                </TableBody>
            </Table>
        </TableContainer>
    )
}
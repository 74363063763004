export interface daneInstalacji {
    selectedRecivers: {
        ogrzewaniePodlogowe: boolean,
        grzejnikiPlytowe: boolean,
        klimakonwertery: boolean,
        otherReciver: boolean,
    },
    ogrzewaniePodlogoweTemp: number,
    grzejnikiPlytoweTemp: number,
    klimakonwerteryTemp: number,
    otherReciverTemp: number,
    otherReciverName: string
}

export const TOGGLE_RECIVER = 'TOGGLE_RECIVER';
export interface ToggleReciver {
    type: typeof TOGGLE_RECIVER,
    payload: {
        element: String | any,
        value: Boolean
    }
}

export const SET_TEMP = 'SET_TEMP';
export interface SetTemp {
    type: typeof SET_TEMP,
    payload: {
        element: String | any,
        temp: number
    }
}

export const SET_OTHER_RECIVER_NAME = 'SET_OTHER_RECIVER_NAME';
export interface SetOtherReciverName {
    type: typeof SET_OTHER_RECIVER_NAME,
    payload: string
}
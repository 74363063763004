import React, { ChangeEvent, useRef, useEffect } from 'react';
import { connect, useSelector } from "react-redux";
import { setSelectionMethod, setBuildingStandard, setPrimodalEnergy, setInsulationPerformance, setShowHide, setValue, setFloorValue, addFloor, removeFloor, calculateBuildingCubature, calcThermalLoad, setFilter } from '../../../redux/actions';
import { SzacowanieMocy } from '../../../redux/actionTypes/szacowanieMocyTypes';
import TabelaPodpowiedziMocy from './TabelaPodpowiedziMocy';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import TextField from '@material-ui/core/TextField';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MyCard from '../../common/MyCard';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';


import TabPanel from '../../common/TabPanel';
import buildingStandards from '../../constants/buildingStandards.json';
import './szacowanieMocy.css';
import clsx from 'clsx';
import stareBudownictwo from '../../../img/stareBudownictwo.svg';
import domyNiskoenergetyczne from '../../../img/domyNiskoenergetyczne.svg';
import noweBudownictwo from '../../../img/noweBudownictwo.svg';
import _ from 'lodash';
import ReactTooltip from 'react-tooltip';

interface OwnProps {
    setActiveStep: Function
}

interface DispatchProps {
    setSelectionMethod: Function,
    setBuildingStandard: Function,
    setPrimodalEnergy: Function,
    setInsulationPerformance: Function,
    setShowHide: Function,
    setValue: Function,
    setFloorValue: Function,
    addFloor: Function, 
    removeFloor: Function,
    calculateBuildingCubature: Function,
    calcThermalLoad: Function,
    setFilter: Function,
}

type Props = OwnProps & DispatchProps & SzacowanieMocy;

function SzacowanieMocyComponent(props: Props) {
    const { 
        setSelectionMethod, selectionMethod, 
        setBuildingStandard, buildingStandard, 
        setPrimodalEnergy, primodalEnergy, 
        setInsulationPerformance, 
        setValue,
        floors, setFloorValue,
        buildingCubature,
        addFloorType,
        addFloor, removeFloor,
        calculateBuildingCubature,
        buildingThermalLoad,
        tempInBuilding,
        buildingArea,
        calcThermalLoad,
        setFilter,
        heatingLimitTemp, 
        setActiveStep
    } = props;



	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => setActiveStep(2), []);

    const tzadInputRef = useRef<HTMLInputElement>(null);

    const outsideTemp = useSelector( (state:any) => state.wynikReducer.filters.outsideTemp);
    useEffect( () => {
        setFilter( 'powerOutput', buildingThermalLoad);
    }, [buildingThermalLoad, heatingLimitTemp, outsideTemp, setFilter]);

    useEffect(() => {
        calcThermalLoad(outsideTemp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tempInBuilding, buildingCubature, buildingArea])

    useEffect(() => {
        if(selectionMethod === 'charakterystyka') {
            setValue( 'primodalEnergy', 0 );
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectionMethod])

    function translateFloorType(type: String) {
        switch (type) {
            case 'firstFloor':
            case 'Parter':
                return 'Parter ';
            case 'attic':
                return 'Poddasze ';
            case 'basement':
                return 'Piwnica ';
            default:
                return 'Piętro ';
        }
    }

    function BuildingStandardCard( props: any ) {
        const { building } = props;
        return (
            <div key={'buildingStandard' + building.name + (new Date().getUTCMilliseconds()) } style={{ width: '100%', }}>
                <MyCard 
                    title={ building.name }
                    description={ building.desc }
                    className={ buildingStandard === building ? 'selected' : '' }
                    key={'buildingStandard' + building.name + (new Date().getUTCMilliseconds()) }
                    onClick={
                        () => {
                            setBuildingStandard(building);
                            setInsulationPerformance( null );
                            setPrimodalEnergy( null );
                            if(building.units === 'primodalEnergy') {
                                setPrimodalEnergy( building.value );
                                setValue( 'heatingLimitTemp', building.heatingLimitTemp );
                            } else {
                                setInsulationPerformance( building.value );
                                setValue( 'heatingLimitTemp', building.heatingLimitTemp );
                            }
                            if(tzadInputRef && tzadInputRef.current) {
                                if(navigator.vendor.match(/apple/i)) {
                                    tzadInputRef.current.scrollIntoView( { behavior: 'smooth' , block: 'start', inline: 'nearest'} );
                                } else {
                                    setTimeout(() => {
                                        if(tzadInputRef && tzadInputRef.current)
                                        tzadInputRef.current.scrollIntoView({behavior: "smooth",});
                                    });
                                }
                            }
                        }
                    } 
                /> 
            </div>
        )
    }

    return (
        <Container className="MuiPaper-elevation2" style={{
			paddingBottom: selectionMethod ? '1rem' : '0',
			marginBottom: '60vh',
			marginTop: '1rem',
			paddingTop: '0',
			backgroundColor: '#EFEFEF',
			borderRadius: '5px',
		}}>
            {/* <Row noGutters>
                <Col>
                    { selectionMethod ? '' : <Row><Col><h4>Dobierz na podstawie:</h4></Col></Row> }
                </Col>
            </Row> */}
            <Row >
                <Col className="justify-content-md-center" style={{ display: 'flex', justifyContent: 'center !important', width: '100%', paddingLeft: 0, paddingRight: 0 }}>

                    <Tabs 
                        className={ selectionMethod ? '' : 'expanded'} 
                        value={ selectionMethod } 
                        onChange={ (e, val) => { 
                            setSelectionMethod(val);
                            calcThermalLoad(outsideTemp);
                        } }
                        aria-label="wrapped label tabs example" 
                        style={{ display: 'flex', justifyContent: 'center !important', width: '100%' }}
                    >
                        <Tab
                            className={ clsx('standardBudynku', 'szacowanie-dobierz-tab', 'MuiPaper-elevation4') }
                            value="standardBudynku"
                            label="Standard wykonania budynku"
                            data-tip="Oszacuj moc pompy ciepła na podstawie standardu, zgodnie z którym wykonany jest budynek."
                        />
                        <Tab 
                            className={ clsx('mocPompy', 'szacowanie-dobierz-tab') }
                            value="mocPompy" 
                            label="Moc pompy" 
                            data-tip="Podaj projektowane obciążenie cieplne budynku/moc pompy ciepła."
                        />
                        <Tab 
                            className={ clsx('charakterystyka', 'szacowanie-dobierz-tab') }
                            value="charakterystyka" 
                            label="Charakterystyka energetyczna" 
                            data-tip="Oszacuj moc pompy ciepła na podstawie charakterystyki energetycznej budynku."
                        />
                    </Tabs> 
                    <ReactTooltip />
                </Col>
            </Row>

            <TabPanel value={ selectionMethod } index="standardBudynku">
                <Row>
                    <Col>
                        <h3>Wybierz standard wykonania budynku</h3>
                        <div style={{display: 'flex', maxWidth: '100%', flexWrap: 'wrap'}}>

                            <Col md={ 4 }>
                                <div className="standard-col-header">
                                <img src={stareBudownictwo} alt="stare budownictwo ikonka" /><h4>{ buildingStandards[0].group }</h4></div>
                                { buildingStandards.slice(0, 3).map( (building, i) => <BuildingStandardCard building={ building } key={ building.name } /> )}
                            </Col>
                            <Col md={ 4 }>
                                <div className="standard-col-header">
                                <img src={domyNiskoenergetyczne} alt="domy niskoenergetyczne ikonka" /><h4>{ buildingStandards[3].group }</h4></div>
                                { buildingStandards.slice(3, 6).map( (building, i) => <BuildingStandardCard building={ building } key={ building.name } /> )}
                            </Col>
                            <Col md={ 4 }>
                                <div className="standard-col-header">
                                <img src={noweBudownictwo} alt="nowe budownictwo ikonka" /><h4>{ buildingStandards[6].group }</h4></div>
                                { buildingStandards.slice(6, 9).map( (building, i) => <BuildingStandardCard building={ building } key={ building.name } /> )}
                            </Col>

                        </div>
                    </Col>
                </Row>
            </TabPanel>

            <TabPanel value={ selectionMethod } index="mocPompy">

                <Container className={ clsx( 'szacowanie-container', '' ) }>
                    <Row>
                        <Col>
                            <p>Moc pompy / Projektowe obciążenie cieplne budynku</p>
                            <TextField 
                                value={ buildingThermalLoad } 
                                id="outlined-basic" 
                                label="Obciążenie cieplne" 
                                variant="outlined" 
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">kW</InputAdornment>
                                }}
                                onChange={ (e:ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => { 
                                    setValue( 'buildingThermalLoad', e.target.value.replace(',', '.') );
                                    setValue( 'heatingLimitTemp', 12 );
                                } }
                                type="number"
                            />
                        </Col>
                    </Row>
                </Container>
            </TabPanel>

            <TabPanel value={ selectionMethod } index="charakterystyka">
                <Container className={ clsx( 'szacowanie-container', '' ) }>
                    <Row>
                        <Col md={6}>
                            <p>Charakterystyka energetyczna/Wskaźnik nieodnawialnej energii pierwotnej</p>
                            <TextField 
                                value={ primodalEnergy } 
                                id="outlined-basic" 
                                label="Wskaźnik" 
                                variant="outlined"
                                InputProps={{
                                    inputProps: { 
                                        min: 0
                                    },
                                    endAdornment: <InputAdornment position="end">kWh/m<sup>2</sup>rok</InputAdornment>
                                }}
                                onChange={ (e:ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => { 
                                    const pE = parseFloat(e.target.value);
                                    setValue( 'primodalEnergy', pE );
                                    const tg = 95<pE ? 15 : 30<pE ? 12 : 10;
                                    setValue( 'heatingLimitTemp', tg);
                                } }
                                type="number"
                            />
                            <p className={ clsx('mt-4', 'text-sm', 'text-gray-500')}>Upewnij się, że wartość współczynnika nieodnawialnej energii pierwotnej nie została zmniejszona np. przez zainstalowaną instalację fotowoltaiczną. Wykorzystanie takiego współczynnika może skutkować wyliczeniem zbyt niskiej mocy pompy ciepła.</p>

                        </Col>
                        <Col md={6}>
                            <TabelaPodpowiedziMocy />
                        </Col>
                    </Row>
                </Container>
                
            </TabPanel>

            { selectionMethod ? 
                <Container className={ clsx( 'szacowanie-container', '' ) }>
                    <Row>
                        <Col>
                            <div ref={tzadInputRef} className={clsx('flex')}>
                                { !(selectionMethod && selectionMethod === 'standardBudynku' && buildingStandard && buildingStandard.units === "primodalEnergy") ? 
                                <div className={clsx('mr-4')}>
                                    <p>Temperatura utrzymywana w budynku</p>
                                    <TextField 
                                        id="outlined-basic" 
                                        label="Temperatura" 
                                        variant="outlined" 
                                        value={ tempInBuilding }
                                        InputProps={{
                                            inputProps: { 
                                                min: 0,
                                                max: 26
                                            },
                                            endAdornment: <InputAdornment position="end">°C</InputAdornment>
                                        }}
                                        error={  !(0 < tempInBuilding && tempInBuilding < 27) }
                                        helperText={ !(0 < tempInBuilding && tempInBuilding < 27) ? "Wprowadź wartość między 0 a 26." : '' }
                                        onChange={ (e) => { 
                                            setValue( 'tempInBuilding', e.target.value );
                                        }} 
                                        type="number"
                                    />
                                </div>
                                : '' }
                                <div className={ clsx( 'opacity-30') }>
                                    <p className={ clsx() }>Graniczna temperatura ogrzewania</p>
                                    <TextField 
                                        id="outlined-basic" 
                                        label="Temperatura" 
                                        variant="outlined" 
                                        className={ clsx('') }
                                        value={ heatingLimitTemp }
                                        InputProps={{
                                            inputProps: { 
                                                min: 10,
                                                max: 15,
                                            },
                                            endAdornment: <InputAdornment position="end">°C</InputAdornment>
                                        }}
                                        onChange={ (e) => { 
                                            setValue( 'heatingLimitTemp', e.target.value );
                                        }} 
                                        type="number"
                                    />
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Container>
             : ''}

            { selectionMethod && selectionMethod === 'standardBudynku' && buildingStandard && buildingStandard.units === "insulationPerformance" ? 
                <Container className={ clsx( 'szacowanie-container', '' ) }>
                    <Row>
                        <Col style={{ }}>
                            <h4>Oblicz kubaturę na podstawie powierzchnii:</h4>
                            { Object.keys(floors).map( (key: any, i) => {
                                return (
                                    <div style={{ marginTop: '1rem'}}>
                                        <p style={{ marginBottom: '4px' }}>{ translateFloorType( floors[key].floorType ) }</p>
                                        <TextField 
                                            id="outlined-basic" 
                                            label="Powierzchnia piętra" 
                                            variant="outlined" 
                                            value={ floors[key].area } 
                                            key={ 'floor' + i}
                                            style={{ marginRight: '1rem' }}
                                            onChange={ (e) => { 
                                                setFloorValue( key, 'area', e.target.value );
                                                const height = floors[key].height;
                                                if( height !== undefined ) {
                                                    const cubature = Number(e.target.value) * height;
                                                    setFloorValue( key, 'floorCubature', cubature );
                                                    calculateBuildingCubature();
                                                }
                                            } } 
                                            type="number" 
                                            InputProps={{
                                                inputProps: { 
                                                    min: 0
                                                },
                                                endAdornment: <InputAdornment position="end">m²</InputAdornment>
                                            }}
                                        />
                                        <TextField 
                                            id="outlined-basic" 
                                            label={ floors[key].floorType === 'attic' ? 'Średnia wysokość' : 'Wysokość piętra' }
                                            variant="outlined" 
                                            value={ floors[key].height }
                                            style={{}}
                                            onChange={ (e) => { 
                                                setFloorValue( key, 'height', e.target.value ) 
                                                const area = floors[key].area;
                                                if( area !== undefined ) {
                                                    const cubature = Number(e.target.value) * area;
                                                    setFloorValue( key, 'floorCubature', cubature );
                                                    calculateBuildingCubature();
                                                }
                                            }} 
                                            InputProps={{
                                                endAdornment: <InputAdornment position="end">m</InputAdornment>
                                            }}
                                            type="number" 
                                        />
                                        { i === 0 ? '' : 
                                            <IconButton 
                                                aria-label="remove floor"
                                                onClick={ () => {
                                                    removeFloor( key );
                                                    calculateBuildingCubature();
                                                } }
                                            >
                                                <RemoveCircleIcon />
                                            </IconButton>
                                        }
                                    </div>
                                )
                            })}
                            <div style={{ marginTop: '2rem', marginBottom: '2rem', display: 'flex', alignItems: 'center' }}>
                                <p style={{ marginRight: '1rem', marginBottom: 0 }}>Dodaj piętro: </p>
                                <FormControl>
                                    <InputLabel id="addFloor-select-label">Dodaj piętro</InputLabel>
                                    <Select
                                        labelId="addFloor-select-label"
                                        id="addFloor-select"
                                        style={{ minWidth: '100px'}}
                                        value={ addFloorType }
                                        onChange={ (e) => setValue( 'addFloorType', e.target.value ) }
                                    >
                                        <MenuItem value='attic' disabled={ _.find(floors, ['floorType', 'attic']) ? true : false }>Poddasze</MenuItem>
                                        <MenuItem value='floor' disabled={ _.filter(floors, ['floorType', 'floor']).length as unknown as number < 3 ? false : true }>Piętro</MenuItem>
                                        <MenuItem value='basement' disabled={ _.find(floors, ['floorType', 'basement']) ? true : false }>Piwnica</MenuItem>
                                    </Select>
                                </FormControl>
                                <IconButton 
                                    aria-label="add floor"
                                    onClick={ () => {
                                        if(addFloorType === '') return;
                                        addFloor();
                                        setValue( 'addFloorType', '' );
                                    }}
                                >
                                    <AddCircleIcon />
                                </IconButton>
                            </div>
                        </Col>
                        <Col style={{  }}>
                            <h4 style={{ marginBottom: '2.7rem'}}>lub wprowadź wartość:</h4>
                            <TextField 
                                id="outlined-basic" 
                                label="Kubatura budynku" 
                                variant="outlined" 
                                value={ buildingCubature }
                                onChange={ (e) => { 
                                    setValue( 'buildingCubature', e.target.value );
                                } } 
                                type="number" 
                                InputProps={{
                                    inputProps: { 
                                        min: 0
                                    },
                                    endAdornment: <InputAdornment position="end">m³</InputAdornment>
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Col>
                    </Row>
                </Container>
             : ''}



            { selectionMethod && ((selectionMethod === 'standardBudynku' && buildingStandard && buildingStandard.units === "primodalEnergy") || selectionMethod === 'charakterystyka') ? 
                <Container className={ clsx( 'szacowanie-container', '' ) }>
                    <Row>
                        <Col style={{ }}>
                            <p>Ogrzewana powierzchnia</p>
                            <TextField 
                                id="outlined-basic" 
                                label="Powierzchnia" 
                                variant="outlined" 
                                value={ buildingArea }
                                onChange={ (e) => { 
                                    setValue( 'buildingArea', e.target.value );
                                } } 
                                type="number" 
                                InputProps={{
                                    inputProps: { 
                                        min: 0
                                    },
                                    endAdornment: <InputAdornment position="end">m<sup>2</sup></InputAdornment>
                                }}
                            />
                        </Col>
                    </Row>
                </Container>
             : ''}
             
        </Container>
    );
}

function mapStateToProps(store: any) {
    const { szacowanieMocyReducer } = store;
    return szacowanieMocyReducer;
}

export default connect<SzacowanieMocy, DispatchProps, OwnProps >(
	mapStateToProps,
	{ setSelectionMethod, setBuildingStandard, setPrimodalEnergy, setInsulationPerformance, setShowHide, setValue, setFloorValue, addFloor, removeFloor, calculateBuildingCubature, calcThermalLoad, setFilter }
  )( SzacowanieMocyComponent );
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  root: {
    width: '100%',
    margin: '0.2rem',
    boxShadow: '1px 1px 4px 0px rgba(0,0,0,0.2)',
    '&:hover': {
        cursor: 'pointer',
        boxShadow: '3px 3px 2px 1px rgba(0,0,0,0.1)'
    }
  },
  title: {
    fontSize: 18,
  },
  description: {
    marginTop: 12,
    fontSize: 11,
  },
  content: {
    padding: '0.3rem',
    '&:last-child': {
      paddingBottom: '0.3rem'
    }
  }
});

export default function MyCard(props: {title: string, description?: any, onClick?: any, className?: String}) {
  const classes = useStyles();
  return (
    <Card className={`${classes.root} ${props.className} myCard`} onClick={props.onClick}>
        <CardActions>
            <CardContent className={classes.content} style={{ width: '100%' }}>
                <Typography variant="h5" component="h4" className={classes.title} style={{ 
                  width: '100%',
                  fontSize: '1rem'
                }}>
                    { props.title }
                </Typography>
                <hr style={{
                  width: '15%',
                  border: '1px #DEDEDE solid',
                  marginLeft: '0',
                  textAlign: 'left'
                }} />
                <Typography color="textSecondary" className={classes.description} style={{ 
                  width: '100%',
                  fontSize: '0.6rem'
                }}>
                    { props.description}
                </Typography>
            </CardContent>
        </CardActions>
    </Card>
  );
}
import { combineReducers } from 'redux';
import { strefaKlimatycznaReducer } from './strefaklimatycznaReducer';
import { szacowanieMocyReducer } from './szacowanieMocyReducer';
import { daneInstalacjiReducer } from './daneInstalacjiReducer';
import { podgrzewanieWodyReducer } from './podgrzewanieWodyReducer';
import { wynikReducer } from './wynikReducer';

import { GET_SELECTION_SUCCESS } from '../actionTypes/wynikTypes';
import _ from 'lodash';

const appReducer = combineReducers({ strefaKlimatycznaReducer, szacowanieMocyReducer, daneInstalacjiReducer, podgrzewanieWodyReducer, wynikReducer });

export const rootReducer = (state: any, action: any) => {
    if(action.type === GET_SELECTION_SUCCESS) {
        const products = _.cloneDeep(state.wynikReducer.productList);
        state = action.payload.selection;
        state.wynikReducer.productList = products;
        state.wynikReducer.filteredProductList = [];
    }
    return appReducer(state, action);
}